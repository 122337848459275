import React, {useState, useEffect, Fragment} from 'react'
//Vendors
import { DataTable } from "simple-datatables"
import "./style.css"
//Helpers
import {roundFloat} from '../../../helpers/numbers'
//Icons
import { ReactComponent as CloudDownIconSVG } from '../../../static/icons/cloud-arrow-down.svg'

export default function DataTableTags(props) {
    const listTags = props.listTags
    const listResults = props.listResults
    const listJsonLogs = props.listJsonLogs
    const resFileFormatProyect = props.resFileFormatProyect
    const dictionaryTags = props.dictionaryTags
    const setDictionaryTags = props.setDictionaryTags

    const [dataTable, setDataTable] = useState(null)

    //Getting dato to set dictionaryTags
    useEffect(() => {
        if (listTags !== null && listResults !== null && listJsonLogs !== null && resFileFormatProyect !== null) {
            let dictionaryTagsAux = {}
            let lengthResults = listResults.length
            listTags.forEach((tag) => { //Get Tags
                if (tag !== '') {
                    dictionaryTagsAux[tag.className] = {
                        'total': 0,
                        'percentage': 0,
                        'added': 0,
                        'deleted': 0,
                        'changed': 0
                    }
                }
            })
            listResults.forEach((res) => { //Get All of the results
                if (res !== '') {
                    try {
                        let aux = res.split(',')
                        if(resFileFormatProyect['className'] !== undefined){
                            let indexClassName = resFileFormatProyect['className']
                            let className = aux[indexClassName]
                            if (dictionaryTagsAux[className] !== undefined && aux[indexClassName] !== undefined) {
                                dictionaryTagsAux[className]['total'] += 1
                            } else {
                                dictionaryTagsAux[className]['total'] = 1
                            }
                        }else{
                            console.error('Error al calcular el total de etiquetas')
                        }
                       
                    } catch (error) {
                        console.error(error)
                    }
                }
            })
            Object.keys(dictionaryTagsAux).forEach((key) => { //Get percentage
                dictionaryTagsAux[key]['percentage'] = roundFloat((dictionaryTagsAux[key]['total'] * 100 / lengthResults), 2)
            })
            listJsonLogs.forEach(log => { //Get all of the logs
                log.descriptions.forEach(description => {
                    try {
                        let acctionType = description.acctionType
                        if (dictionaryTagsAux[description.currentTag] !== undefined) {
                            if (acctionType === 'Added') {
                                dictionaryTagsAux[description.currentTag]['added'] += 1
                            }
                        }
                        if (dictionaryTagsAux[description.previousTag] !== undefined) {
                            if (acctionType === 'Deleted') {
                                dictionaryTagsAux[description.previousTag]['deleted'] += 1
                            }
                            if (acctionType === 'Changed') {
                                dictionaryTagsAux[description.previousTag]['changed'] += 1
                            }
                        }
                    } catch (error) {
                        console.error(error)
                    }
                });
            });
            
            //console.log(dictionaryTagsAux);
            setDictionaryTags(dictionaryTagsAux)
        }
    }, [listTags, listResults, resFileFormatProyect, listJsonLogs])// eslint-disable-line react-hooks/exhaustive-deps

    //Linked datatable
    useEffect(() => {
        if (dictionaryTags !== null) {
            //console.log(dictionaryTags);
            const myTable = document.getElementById('tagsTable');
            if (myTable) {
                // Customise our labels
                var labelData = {
                    placeholder: "Buscar...",
                    perPage: "{select} Registros por página",
                    noRows: "No hay datos que mostrar",
                    info: "Mostrando de {start} a {end} de los {rows} registros (Página {page} de {pages})"
                };
                let dataTableAux = new DataTable(myTable, {
                    labels: labelData,
                    header: true,
                });
                setDataTable(dataTableAux)
            }
        }
    }, [dictionaryTags])

    function downloadTableAsCSV(dataTable) {
        // Export the current page as a .csv file
        if (dataTable !== null){
            dataTable.export({
                type: "csv",
                filename: "tabla-etiquetas",
                //selection: dataTable.currentPage
            });
        }
    }

    return (
        <Fragment>
        { dictionaryTags && (
            <div className="card p-0">
                <div className="card-header text-center">
                    <h4>
                        Etiquetas &nbsp;&nbsp;
                        <button type="button" className="btn btn-outline-success"
                                onClick={() => downloadTableAsCSV(dataTable)}
                        >
                            <CloudDownIconSVG/>
                        </button>
                    </h4>
                </div>
                <table id='tagsTable' className='table text-left table-hover dataTable'>
                    <thead>
                        <tr>
                            <th>Etiqueta</th>
                            <th>Total</th>
                            <th>%</th>
                            <th>Agregadas</th>
                            <th>Eliminadas</th>
                            <th>Cambiadas</th>
                            {/*<th>City</th>
                            <th data-type="date" data-format="YYYY/MM/DD">Start Date</th>
                            <th>Completion</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(dictionaryTags).map((className, index) => (
                                <tr key={index}>
                                    <td>{className}</td>
                                    <td>{dictionaryTags[className].total}</td>
                                    <td>{dictionaryTags[className].percentage}</td>
                                    <td>{dictionaryTags[className].added}</td>
                                    <td>{dictionaryTags[className].deleted}</td>
                                    <td>{dictionaryTags[className].changed}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            )
        }
        </Fragment>
    )
}
