import React, {useState, Fragment } from 'react'
import Footer from '../Footer'
//Models
import { LocalFile } from '../../models/LocalFile';
//Helpers
import { validExtensionImage } from '../../helpers/validExtensionImage'
import { getFileExtension } from '../../helpers/fileExtension'
import { verifyPermissionHandle} from '../../helpers/fileSystemAPI'
import { LoadingMessage, changeTextLoadingMessage, changeFooterLoadingMessage } from '../../helpers/message'
import useDocumentTitle from '../../helpers/useDocumentTitle'
//Vendors
import Swal from 'sweetalert2'
//Icons
import { ReactComponent as FolderOpenIconSVG } from '../../static/icons/folder2-open.svg'
import { ReactComponent as ImagesIconSVG } from '../../static/icons/images.svg'
import { ReactComponent as FilesIconSVG } from '../../static/icons/files.svg'

function ResConverter(props) {
    const [dirHandle, setDirHandle] = useState(null)
    const [listImages, setListImages] = useState(null)
    const [listResJSONFiles, setListResJSONFiles] = useState(null)
    const FORMAT_RES_FILE = '.res'
    const FORMAT_RESJSON_FILE = '.res.json'

    useDocumentTitle(props.title);

    async function generateResFileFromResJsonFile(imageName, resJsonLocalFile, dirHandle) {
        let res = false
        try {
            const jsonString = await resJsonLocalFile.readFile()
            const jsonObj = JSON.parse(jsonString)

            let i = 0
            let defaultValue = 0
            let newContent = ''
            if ("predictions" in jsonObj){
                jsonObj["predictions"].forEach( async (prediction) => {
                    i+=1
                    newContent += `${i},`                       //"id": 0,
                    newContent += `${prediction?.clase},`       //"className": 1,
                    newContent += `${defaultValue},`            //"area": 2,
                    newContent += `${defaultValue},`            //"center_x": 3,
                    newContent += `${defaultValue},`            //"center_y": 4,
                    newContent += `${prediction?.coords['0']},` //"upper_left_x": 5,
                    newContent += `${prediction?.coords['1']},` //"upper_left_y": 6,
                    newContent += `${prediction?.coords['2']},` //"lower_right_x": 7,
                    newContent += `${prediction?.coords['3']},` //"lower_right_y": 8,
                    newContent += `${prediction?.score},`       //"probability": 9,
                    newContent += `${defaultValue},`            //"width": 10,
                    newContent += `${defaultValue},`            //"height": 11,
                    newContent += `\n`
                })
            }
            if ("publicidades" in jsonObj) {
                jsonObj["publicidades"].forEach(async (prediction) => {
                    i+=1
                    newContent += `${i},`                       //"id": 0,
                    newContent += `${prediction?.clase},`       //"className": 1,
                    newContent += `${defaultValue},`            //"area": 2,
                    newContent += `${defaultValue},`            //"center_x": 3,
                    newContent += `${defaultValue},`            //"center_y": 4,
                    newContent += `${prediction?.coords['0']},` //"upper_left_x": 5,
                    newContent += `${prediction?.coords['1']},` //"upper_left_y": 6,
                    newContent += `${prediction?.coords['2']},` //"lower_right_x": 7,
                    newContent += `${prediction?.coords['3']},` //"lower_right_y": 8,
                    newContent += `${prediction?.score},`       //"probability": 9,
                    newContent += `${defaultValue},`            //"width": 10,
                    newContent += `${defaultValue},`            //"height": 11,
                    newContent += `\n`
                })
            }
            const fileHandle = await dirHandle.getFileHandle(imageName + FORMAT_RES_FILE, { create: true });
            const tmplocalFile = new LocalFile(fileHandle)
            await tmplocalFile.writeFile(newContent)
            res = true
            
        } catch (error) {
            console.error(error)
            res = false
        }

        return res
    }

    async function selectProyectDirectory() {
        let dirHandleAux = null
        let listImagesAux = []
        let listResJSONFilesAux = []
        
        try {
            dirHandleAux = await window.showDirectoryPicker();
            await verifyPermissionHandle(dirHandleAux, true)
        } catch (err) {
            console.error(err.name, err.message);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'No se pudo cargar el proyecto'
            });
            return
        }

        let loadingMessage = LoadingMessage(
            'Por favor espere!',
            'Cargando imagenes y etiquetas del proyecto',
            '',
        )
        loadingMessage.fire()

        //Get images
        let i = 0
        for await (const item of dirHandleAux.values()) {
            try {
                if (item.kind === 'file') {
                    i+=1
                    let fileAux = new LocalFile(item)
                    changeTextLoadingMessage(fileAux.name)
                    changeFooterLoadingMessage(String(i))
                    if (validExtensionImage(getFileExtension(fileAux.name))) { //If is it a Image?
                        const file = await fileAux.getTheFile();
                        listImagesAux.push(file)                        
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }

        //Get .res.json of lstImages and converte to .res
        listImagesAux.forEach( async (image) => {
            try {
                console.log(image?.name + FORMAT_RESJSON_FILE)
                
                if (dirHandleAux){
                    const fullNameResJSONFile = image?.name + FORMAT_RESJSON_FILE
                    const fileHandle = await dirHandleAux.getFileHandle(fullNameResJSONFile, { create: false });
                    if (fileHandle){
                        const tmpResJsonLocalFile = new LocalFile(fileHandle)
                        listResJSONFilesAux.push(tmpResJsonLocalFile)
                        let resp = await generateResFileFromResJsonFile(image?.name, tmpResJsonLocalFile, dirHandleAux)
                        if (resp){ //If res file gerete succesfuly
                            await dirHandleAux.removeEntry(fullNameResJSONFile);
                        }
                    }
                }
            } catch (error) {
                console.error(error)
            }
        });
        
        console.log('dirHandleAux: ',dirHandleAux)
        console.log('listImagesAux: ', listImagesAux)
        console.log('listResJSONFilesAux: ', listResJSONFilesAux)
    
        setDirHandle(dirHandleAux)
        setListImages(listImagesAux)
        setListResJSONFiles(listResJSONFilesAux)
        Swal.close();

        Swal.fire({
            icon: 'success',
            title: 'La conversión de los archivos ResJson completada',
            showConfirmButton: true,
        })
    }

    return (
        <Fragment>
            <div className='container'>
                <div className='row m-2'>
                    <h3 className='text-center'>Convertidor de archivos ResJSON (.res.json) a Res (.res)</h3>
                </div>
                <div className='row m-2'>
                    <p className='text-center'>Carga un proyecto dando click, los archivos ResJSON (.res.json) seran remplazdos por Res (.res), espera el mensaje de confirmacion</p>
                </div>
                <div className='row m-2'>
                    <p className='text-center'>No olvides agregar el archivo <b>.names</b> a tu proyecto para que funcione de manera correcta en los demas modulos</p>
                </div>
                <div className='row m-2'>
                    <button
                        className="btn btn-primary" type="button"
                        onClick={() => { selectProyectDirectory() }}
                    >
                        Cargar proyecto
                    </button>
                </div>
                <br />
                {/*Info proyecto*/}
                <div className='row m-2'>
                    <div className='col-4'>
                        {dirHandle && <h5 className='text-center'><FolderOpenIconSVG /> <i>{dirHandle?.name}</i></h5> }
                    </div>
                    <div className='col-4'>
                        {listImages && <h5 className='text-center'><ImagesIconSVG /> Imagenes: <i>{listImages?.length}</i></h5> }
                    </div>
                    <div className='col-4'>
                        {listResJSONFiles && <h5 className='text-center'><FilesIconSVG /> Archivos ResJSON: <i>{listResJSONFiles?.length}</i></h5> }
                    </div>
                </div>
                <br />
            </div>
            <br/>
            <Footer/>
        </Fragment>
    )
}

export default ResConverter