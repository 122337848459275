export class ImageLog{
	date
	idImage
	user
	duration
	descriptions
}


export class LogDescription{
	idArticle
	probability
	isCorrect
	acctionType
	previousTag
	currentTag
}
