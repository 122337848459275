import React, {useState, Fragment } from 'react'
import Footer from '../Footer'
//Models
import { LocalFile } from '../../models/LocalFile';
//Helpers
import { validExtensionImage } from '../../helpers/validExtensionImage'
import { getFileExtension } from '../../helpers/fileExtension'
import { verifyPermissionHandle, loadExifDataOfImage, getAutoRotateBlobDataOfImageUsingExifData} from '../../helpers/fileSystemAPI'
import { LoadingMessage, changeTextLoadingMessage, changeFooterLoadingMessage, SmallNoty } from '../../helpers/message'
import useDocumentTitle from '../../helpers/useDocumentTitle'
//Vendors
import Swal from 'sweetalert2'
//Icons
import { ReactComponent as FolderOpenIconSVG } from '../../static/icons/folder2-open.svg'
import { ReactComponent as ImagesIconSVG } from '../../static/icons/images.svg'

function ExifAutoremoveAutorotate(props) {
    const [dirHandle, setDirHandle] = useState(null)
    const [listImagesFiles, setListImagesFiles] = useState(null)
    const [listImagesFilesAutoRotate, setListImagesFilesAutoRotate] = useState(null)
    const [listImagesFilesNotAutoRotate, setListImagesFilesNotAutoRotate] = useState(null)
    const [listImagesFilesWithoutExifOrientation, setListImagesFilesWithoutExifOrientation] = useState(null)

    useDocumentTitle(props.title);

    /**
     * [Async] Auto Rotate nnd remove Exif data of Image, overwrite the original image
     * @param  {LocalFile} imageLocalFile filename, ej: 'julio.txt, 'hola.jpg'
     * @return {Boolean} true on success, false on error
     */
    async function autorotateAndRemoveExifDataFromImage(imageLocalFile) {
        let res = false
        try {
            let file = await imageLocalFile.getTheFile();

            //Read Exif Data
            let exifDataImage = await loadExifDataOfImage(file)
            //console.log('Original', exifDataImage);
            if (exifDataImage){
                //Get image blob data autorotate using its exif data
                let newBlobRotated = await getAutoRotateBlobDataOfImageUsingExifData(file)
                if (newBlobRotated !== null) {
                    const writable = await imageLocalFile.fileHandle.createWritable();
                    await writable.write(newBlobRotated);
                    await writable.close();

                    //Set res as true
                    res = true
                }
    
                //Read again image
                //file = await imageLocalFile.getTheFile();
                //let exifDataImageAfter = await loadExifDataOfImage(file)
                //console.log('Despues', exifDataImageAfter);
                
            }
            
        } catch (error) {
            console.error(error, imageLocalFile)
            SmallNoty('error', `Error al intentar autorotar y eliminal el tag orientacion Exif de la imagen ${imageLocalFile.name}`, 12000)
            res = false
        }

        return res
    }

    async function selectProyectDirectory() {
        let dirHandleAux = null
        let listImagesFilesAux = []
        let listImagesFilesAutoRotateAux = []
        let listImagesFilesNotAutoRotateAux = []
        let listImagesFilesWithoutExifOrientationAux = []
        
        try {
            dirHandleAux = await window.showDirectoryPicker();
            await verifyPermissionHandle(dirHandleAux, true)
        } catch (err) {
            console.error(err.name, err.message);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'No se pudo cargar el proyecto'
            });
            return
        }

        let loadingMessage = LoadingMessage(
            'Por favor espere!',
            'Cargando imagenes y etiquetas del proyecto',
            '',
        )
        loadingMessage.fire()

        //Get images
        let i = 0
        for await (const item of dirHandleAux.values()) {
            try {
                if (item.kind === 'file') {
                    i+=1
                    let localFileAux = new LocalFile(item)
                    changeTextLoadingMessage(localFileAux.name)
                    changeFooterLoadingMessage(String(i))
                    if (validExtensionImage(getFileExtension(localFileAux.name))) { //If is it a Image?
                        listImagesFilesAux.push(localFileAux)
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }

        Swal.close();


        //Rotate and Remove Exif Data for each image in project
        loadingMessage = LoadingMessage(
            'Por favor espere!',
            'Rotando y removiendo Exif de imágenes',
            '',
        )
        loadingMessage.fire()
        const totalImages = listImagesFilesAux.length
        i = 0
        for (const imageFile of listImagesFilesAux) {
            i+=1
            changeTextLoadingMessage(imageFile.name)
            changeFooterLoadingMessage(`${i} de ${totalImages}`)
            try {
                let exifDataImage = await loadExifDataOfImage(await imageFile.getTheFile())
                if (exifDataImage?.Orientation) {
                    let res = await autorotateAndRemoveExifDataFromImage(imageFile)
                    if (res) {
                        listImagesFilesAutoRotateAux.push(imageFile)
                    }else{
                        listImagesFilesNotAutoRotateAux.push(imageFile)
                    }
                }else{
                    listImagesFilesWithoutExifOrientationAux.push(imageFile)
                }
            } catch (error) {
                console.error(error)
            }
        }
        
        console.log('dirHandleAux: ',dirHandleAux)
        console.log('listImagesFilesAux: ', listImagesFilesAux)
        console.log('listImagesFilesAutoRotateAux: ', listImagesFilesAutoRotateAux)
        console.log('listImagesFilesWithoutExifOrientationAux: ', listImagesFilesWithoutExifOrientationAux)
    
        setDirHandle(dirHandleAux)
        setListImagesFiles(listImagesFilesAux)
        setListImagesFilesAutoRotate(listImagesFilesAutoRotateAux)
        setListImagesFilesNotAutoRotate(listImagesFilesNotAutoRotateAux)
        setListImagesFilesWithoutExifOrientation(listImagesFilesWithoutExifOrientationAux)
        
        Swal.close();

        Swal.fire({
            icon: 'success',
            title: `${listImagesFilesAutoRotateAux.length} Imagenes fueron rotadas correctamente`,
            showConfirmButton: true,
        })
    }

    return (
        <Fragment>
            <div className='container'>
                <div className='row m-2'>
                    <h3 className='text-center'>Exif rotacion y eliminacion automática</h3>
                </div>
                <div className='row m-2'>
                    <p className='text-center'>Rota las imágenes de un proyecto automáticamente usando la etiqueta <b>Exif</b> de orientación para cada imagen.</p>
                    <p className='text-center'>Remueve la etiqueta <b>Exif</b> de orientación sobreescribiendo la imagen original.</p>
                </div>
                <div className='row m-2'>
                    <p className='text-center'><i>Las imágenes resultantes perderán la etiqueta <b>Exif</b> de orientación.</i></p>
                </div>
                <div className='row m-2'>
                    <button
                        className="btn btn-primary" type="button"
                        onClick={() => { selectProyectDirectory() }}
                    >
                        Cargar proyecto
                    </button>
                </div>
                <br />
                {/*Info proyecto*/}
                <div className='row m-2'>
                    <div className='col-6'>
                        {dirHandle && <h5 className='text-center'><FolderOpenIconSVG /> <i>{dirHandle?.name}</i></h5> }
                    </div>
                    <div className='col-6'>
                        {listImagesFiles && <h5 className='text-center'><ImagesIconSVG /> Imagenes Leidas: <i>{listImagesFiles?.length}</i></h5> }
                    </div>
                </div>
                <br />
                {/*Resultados*/}
                <div className='row m-2'>
                    <div className='col-4'>
                        {listImagesFilesAutoRotate && <h5 className='text-center'><ImagesIconSVG /> Autorotadas: <i>{listImagesFilesAutoRotate?.length}</i></h5>}
                    </div>
                    <div className='col-4'>
                        {listImagesFilesNotAutoRotate && <h5 className='text-center'><ImagesIconSVG /> NO Autorotadas: <i>{listImagesFilesNotAutoRotate?.length}</i></h5>}
                    </div>
                    <div className='col-4'>
                        {listImagesFilesWithoutExifOrientation && <h5 className='text-center'><ImagesIconSVG /> Sin Exif de Orientacion: <i>{listImagesFilesWithoutExifOrientation?.length}</i></h5>}
                    </div>
                </div>
                <br />
            </div>
            <br/>
            <Footer/>
        </Fragment>
    )
}

export default ExifAutoremoveAutorotate