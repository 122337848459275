import Swal from 'sweetalert2';
import {ConfirmActionMessage} from './message'

export const LOCAL_STORAGE = window.localStorage;


export function updateLocalStorageDirectory(nameCurrentDirectory){
    const nameLastDirectory = LOCAL_STORAGE.getItem('nameLastDirectory');
    if(nameLastDirectory !== nameCurrentDirectory){
        LOCAL_STORAGE.setItem('nameLastDirectory', nameCurrentDirectory);
    }
}

export function updateLocalStorageLastImageVisited(nameCurrentImage){
    const nameLastImageVisited = LOCAL_STORAGE.getItem('nameLastImageVisited');
    if(nameLastImageVisited !== nameCurrentImage){
        LOCAL_STORAGE.setItem('nameLastImageVisited', nameCurrentImage);
    }
}

export function updateLocalStorageInitTimeInThisImage(newTime){
    const initTimeInThisImage = LOCAL_STORAGE.getItem('initTimeInThisImage');
    if(newTime !== initTimeInThisImage){
        LOCAL_STORAGE.setItem('initTimeInThisImage', newTime);
    }
}

export function updateLocalStorageUserName(newUserName){
    const userName = LOCAL_STORAGE.getItem('userName');
    if(newUserName !== userName){
        LOCAL_STORAGE.setItem('userName', newUserName);
    }
}

export async function getUserName() {
    const { value: userName } = await Swal.fire({
                title: '¿Cuál es tu nombre?',
                input: 'text',
                // inputLabel: 'Introduce tu nombre',
                inputPlaceholder: 'Introduce tu nombre...',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            })
            console.log(userName)
            if (userName) {
               await confirmUserName(userName)
            }
}

export async function confirmUserName(userName) {
    await ConfirmActionMessage.fire({
        icon: 'warning',
        title: `Tu nombre es: ${userName}?`,
        }).then((result) => {
            if (result.isConfirmed) {
                updateLocalStorageUserName(userName)
            }
        })
}