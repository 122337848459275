import React, { Fragment } from 'react'

export default function DirClasses(props) {
    
    const dirClasses = props.dirClasses
    
    let contentHTML = null


    if (dirClasses === null) {
        contentHTML = ''
    } else {
        contentHTML = (
            <Fragment>
                <div className="col-sm-12 col-md-12 col-lg-12 " style={{ height: '300px' }}>
                    <div className="row h-100">
                        <div className="col-sm-12 col-md-12 col-lg-12 B h-100 p-2">
                            <h5 className='text-center'>Vista previa clases</h5>
                            <div className="card border-primary h-100 overflow-auto">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-center">
                                                Indice
                                            </th>
                                            <th scope="col" className="text-center">
                                                Nombre
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        Object.keys(dirClasses).map((element, index) => (
                                            <tr className="align-middle text-center" key={String(index)}>
                                                <th scope="row">
                                                    {index}
                                                </th>
                                                <td>
                                                    {element}
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        contentHTML
    )
}
