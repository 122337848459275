import React, { useState, Fragment } from 'react'
//Vendors
import Swal from 'sweetalert2'
//Components
import Footer from '../Footer'
import DataTableTags from './DataTableTags/DataTableTags';
import DataTableTagChanges from './DataTableTagChanges/DataTableTagChanges';
import TagChart from './TagChart';
import UsersTable from './UsersTable';
//Models
import { LocalFile } from '../../models/LocalFile';
import { Tag } from '../../models/Tag';

//Helpers
import { validExtensionImage } from '../../helpers/validExtensionImage'
import { getFileExtension } from '../../helpers/fileExtension'
import { verifyPermissionHandle, resFileFormatDefault } from '../../helpers/fileSystemAPI'
import { LoadingMessage, changeTextLoadingMessage, SmallNoty } from '../../helpers/message'
import { roundFloat } from '../../helpers/numbers'
import useDocumentTitle from '../../helpers/useDocumentTitle'
//Icons
import { ReactComponent as FolderOpenIconSVG } from '../../static/icons/folder2-open.svg'
import { ReactComponent as ImagesIconSVG } from '../../static/icons/images.svg'
import { ReactComponent as TagsIconSVG } from '../../static/icons/tags.svg'
import { ReactComponent as FilesIconSVG } from '../../static/icons/files.svg'
import { ReactComponent as StopWatchSVG } from '../../static/icons/stopwatch.svg'

export default function QAReport(props) {

    const [dirHandle, setDirHandle] = useState(null)
    const [listImages, setListImages] = useState(null)
    const [listTags, setListTags] = useState(null)
    const [listResults, setListResults] = useState(null)
    const [listJsonLogs, setListJsonLogs] = useState(null)
    const [resFileFormatProyect, setResFileFormatProyect] = useState(resFileFormatDefault)
    const [dictionaryTags, setDictionaryTags] = useState(null)
    const [totalTimeLogs, setTotalTimeLogs] = useState(1)
    const formatResFileRes = '.res'
    const formatLogFile = '.log.json'

    useDocumentTitle(props.title);
    
    async function selectProyectDirectory() {
        let dirHandleAux = null
        let listImagesAux = []
        let listTagsAux = []
        let listResultsAux = []
        let listJsonLogsAux = []
        let totalTimeLogsAux = 0

        try {
            dirHandleAux = await window.showDirectoryPicker();
            await verifyPermissionHandle(dirHandleAux, true)
        } catch (err) {
            console.error(err.name, err.message);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'No se pudo cargar el proyecto'
            });
            return
        }

        let loadingMessage = LoadingMessage(
            'Por favor espere!',
            'Cargando imagenes y etiquetas del proyecto',
            '',
        )
        loadingMessage.fire()

        //Get images, all res files, all logs,  .names file and 'config.json' file
        for await (const item of dirHandleAux.values()) {
            try {
                if (item.kind === 'file') {
                    let fileAux = new LocalFile(item)
                    changeTextLoadingMessage(fileAux.name)
                    if (validExtensionImage(getFileExtension(fileAux.name))) { //If is it a Image?
                        const image = await fileAux.getTheFile();
                        listImagesAux.push(image)
                        //Get res file of this image
                        const resFileHandleThisImage = await dirHandleAux.getFileHandle(fileAux.name + formatResFileRes, { create: true })
                        const resFileThisImage = new LocalFile(resFileHandleThisImage) //Usamos nuestra clase
                        const contResults = await resFileThisImage.readFile()
                        if (contResults !== ''){
                            const linesRes = await resFileThisImage.readLinesFile() //There is a json list
                            listResultsAux = listResultsAux.concat(linesRes)
                        }
                        //Get log file of this image\
                        const logFileHandleThisImage = await dirHandleAux.getFileHandle(fileAux.name + formatLogFile, { create: true })
                        const logFileThisImage = new LocalFile(logFileHandleThisImage) //Usamos nuestra clase
                        const contentLog = await logFileThisImage.readFile() //There is a json list
                        if (contentLog){
                            try {
                                const listJson = JSON.parse(contentLog)
                                listJsonLogsAux = listJsonLogsAux.concat(listJson)                            
                                // eslint-disable-next-line
                                listJson.forEach((log) => {
                                    //console.log(log.duration)
                                    if (log.duration > 3600){ //si tardo mas de 3600 seg = 60 min = 1 hr
                                        console.warn(`Algo esta mal, capturista [${log.user}] tardo [${roundFloat((log.duration / 60 / 60), 2)}] hora(s) en la imagen ${fileAux.name}`)
                                        SmallNoty('warning', `Algo esta mal, capturista <b>[${log.user}]</b> tardo <b>[${roundFloat((log.duration / 60 / 60), 2)}] hora(s)</b> en la imagen ${fileAux.name}`, 10000)
                                    }
                                    totalTimeLogsAux+=log.duration
                                })
                            } catch (error) {
                                console.error('Error al extraer duracion de logs', error)
                            }
                        }
                    }
                    if ('names' === getFileExtension(fileAux.name)) { //if is a tags file
                        let lines = await fileAux.readLinesFile()
                        lines.forEach(line => {
                            let aux = line.split(',')
                            if (aux[1]){
                                let t = new Tag(aux[0], aux[1])
                                listTagsAux.push(t)
                            }
                        });
                    }
                    if ('config.json' === fileAux.name) {
                        let content = await fileAux.readFile()
                        let json_obj = JSON.parse(content)
                        setResFileFormatProyect(json_obj.resFileFormat)
                        console.info(`Archivo de config cargado, formato archivos 'res': `, json_obj.resFileFormat)
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }


        console.log('dirHandleAux: ', dirHandleAux)
        console.log('listImages: ', listImagesAux)
        console.log('listTags: ', listTagsAux)
        console.log('listResults: ', listResultsAux)
        console.log('listJsonLogsAux: ', listJsonLogsAux)
        console.log('totalTimeLogsAux: ', totalTimeLogsAux)
        setDirHandle(dirHandleAux)
        setListImages(listImagesAux)
        setListTags(listTagsAux)
        setListResults(listResultsAux)
        setListJsonLogs(listJsonLogsAux)
        setTotalTimeLogs(totalTimeLogsAux)

        Swal.close();
    }

    return (
        <Fragment>
            <div className='container'>
                <div className='row m-2'>
                    <h2 className='text-center'>Reporte QA</h2>
                </div>
                <div className='row m-2'>
                    <p className="fs-5 text-muted text-center">Carga un proyecto se mostrará su información: nombre, cantidad de imagenes, etiquetas y articulos que contiene. Asi como el historial de cambios incluyendo los detalles sobre las etiquetas agregadas, eliminadas y cambiadas.</p>
                </div>
                <div className='row m-2'>
                    <button
                        className="btn btn-primary" type="button"
                        onClick={() => { selectProyectDirectory() }}
                    >
                        Cargar proyecto
                    </button>
                </div>
                <br />
                {/*Informacion del proyecto */}
                {
                    (dirHandle && listImages && listTags && listResults) &&
                    <div className='row m-2 bg-light'>
                        <div className="card-header text-center"><h3><FolderOpenIconSVG/> Proyecto: <b>{dirHandle?.name}</b></h3></div>
                        <div className='col-3 p-2'>
                            <h5 className='text-center'><StopWatchSVG/> Tiempo total: <b>{roundFloat(totalTimeLogs/60, 2)} min</b></h5>
                        </div>
                        <div className='col-3 p-2'>
                            <h5 className='text-center'><TagsIconSVG /> Etiquetas: <b>{listTags.length}</b></h5>
                        </div>
                        <div className='col-3 p-2'>
                            <h5 className='text-center'><ImagesIconSVG /> Imagenes: <b>{listImages.length}</b></h5>
                        </div>
                        <div className='col-3 p-2'>
                            <h5 className='text-center'><FilesIconSVG /> Detecciones: <b>{listResults.length}</b></h5>
                        </div>
                    </div>
                }
                <br />
                {/*Tabla timepos por usuario */}
                <div className='row m-2'>
                    <UsersTable
                        listJsonLogs={listJsonLogs}
                    />
                </div>
                <br />
                {/*Datatable Etiquetas del proyecto */}
                <div className='row m-2'>
                    <DataTableTags
                        dictionaryTags = {dictionaryTags}
                        setDictionaryTags = {setDictionaryTags}
                        listTags = {listTags}
                        listResults = {listResults}
                        listJsonLogs = {listJsonLogs}
                        resFileFormatProyect = {resFileFormatProyect}
                    />
                </div>
                <br/>
                {/*Graficas etiquetas */}
                <div className='row m-2'>
                    {
                        dictionaryTags && (
                            <Fragment>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <div className="card bg-light">
                                        <div className="card-header text-center">Etiquetas Totales (en uso)</div>
                                        <TagChart
                                            dictionaryTags={dictionaryTags}
                                            attributeObjetive={"total"}
                                            showLegends={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <div className="card bg-light">
                                        <div className="card-header text-center">Etiquetas Agregadas</div>
                                        <TagChart
                                            dictionaryTags={dictionaryTags}
                                            attributeObjetive={"added"}
                                            showLegends={false}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4">
                                    <div className="card bg-light">
                                        <div className="card-header text-center">Etiquetas Eliminadas</div>
                                        <TagChart
                                            dictionaryTags={dictionaryTags}
                                            attributeObjetive={"deleted"}
                                            showLegends={false}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        )
                    }
                </div>
                <br />
                {/*Tabla de cambios de etiquetas*/}
                <div className='row m-2'>
                    <DataTableTagChanges
                        listTags={listTags}
                        listJsonLogs={listJsonLogs}
                        resFileFormatProyect={resFileFormatProyect}
                    />
                </div>
                <br />
                
            </div>
            <br />
            <Footer />
        </Fragment>
    )
}
