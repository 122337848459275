import React, { useState, useEffect, Fragment} from 'react'

import { Doughnut } from 'react-chartjs-2';
import {RGBColor} from '../../models/RGBColor'

export default function TagChart(props) {

    const dictionaryTags = props.dictionaryTags
    const attributeObjetive = props.attributeObjetive;
    const showLegends = props.showLegends;
    
    const [listTags, setlistTags] = useState(null)
    const [dataDoughnut, setDataDoughnut] = useState(null)
    
    const options = {
        maintainAspectRatio: false,	// Don't maintain w/h ratio
        tooltips: {
            enabled: true
        },
        plugins: {
            legend: {
                position: 'bottom',
                display: showLegends
            },
        }
    }
    
    useEffect(() => {
        if (dictionaryTags !== null && attributeObjetive !== null) {
            
            let listTagsAux = []
            let labels = []
            let data = []
            let backgroundColors = []
            let borderColors = []
            let total = 0
            let rgbColor = new RGBColor()
            
            Object.keys(dictionaryTags).forEach( key =>{
                let tagAux = {
                    className: key,
                    quantity: dictionaryTags[key][attributeObjetive]
                }
                if (tagAux.quantity > 0){
                    listTagsAux.push(tagAux)
                }
            })
            //console.log(listTagsAux)
            
            setlistTags(listTagsAux)
            
            listTagsAux.forEach(element => {
                total += element.quantity
            });

            
            listTagsAux.forEach(element => {
                let arrayRGBColor = rgbColor.getColor()
                labels.push(Number.parseFloat((element.quantity * 100 / total)).toFixed(2) + '% ' + element.className)
                data.push(element.quantity)
                backgroundColors.push(`rgba(${arrayRGBColor[0]},${arrayRGBColor[1]},${arrayRGBColor[2]},0.5)`)
                borderColors.push(`rgba(${arrayRGBColor[0]},${arrayRGBColor[1]},${arrayRGBColor[2]},1)`)
            });

            let dataDoughnut = {
                labels: labels,
                datasets: [
                    {
                        data: data,
                        backgroundColor: backgroundColors,
                        borderColor: borderColors,
                        borderWidth: 1,
                    },
                ],
            };

            setDataDoughnut(dataDoughnut)
        }
    }, [dictionaryTags])// eslint-disable-line react-hooks/exhaustive-deps
    
    

    return (
        <Fragment>{
            (listTags?.length) > 0 
            ?
                <article className="char-container p-2" style={{ height: '40vh' }}>
                    <Doughnut data={dataDoughnut} options={options} />
                </article>
            :
                <h3 className="text-center p-2">Sin datos</h3>
        }</Fragment>
    )
}
