import React, {useState, useEffect, Fragment} from 'react'
//Vendors
import { DataTable } from "simple-datatables"
import "./style.css"
import { ReactComponent as CloudDownIconSVG } from '../../../static/icons/cloud-arrow-down.svg'
/*
Estructura de 'dictionaryTagChanges'
{
    "classNamePreviousA": {
        "classNameCurrent_1" : 1,
        "classNameCurrent_2": 1
    },
    "classNamePreviousB": {
        "classNameCurrent_1": 1,
        "classNameCurrent_2": 1
    }
}

{
    "Pepsi": {
        "CocaCola": 2,
        "Mirinda": 3
    },
    "Fanta": {
        "Jarrito": 1,
        "RedCola": 1
    }
}
*/

export default function DataTableTagChanges(props) {
    
    //const listTags = props.listTags
    const listJsonLogs = props.listJsonLogs

    const [dictionaryTagChanges, setDictionaryTagChanges] = useState(null)
    const [dataTable, setDataTable] = useState(null)

    //Getting dato to set dictionaryTags
    useEffect(() => {
        if (listJsonLogs !== null ) {
            let dictionaryTagChangesAux = {}
            listJsonLogs.forEach(log => { //Get all of the logs
                log.descriptions.forEach(description => {
                    let previousTag = description.previousTag
                    let currentTag = description.currentTag
                    let acctionType = description.acctionType
                    if (acctionType === 'Changed' && previousTag !== undefined && currentTag !== undefined ){
                        if (dictionaryTagChangesAux[previousTag]){
                            if (dictionaryTagChangesAux[previousTag][currentTag]){
                                dictionaryTagChangesAux[previousTag][currentTag] += 1
                            }else{
                                dictionaryTagChangesAux[previousTag][currentTag] = 1
                            }
                        }else{
                            dictionaryTagChangesAux[previousTag] = {}
                            dictionaryTagChangesAux[previousTag][currentTag] = 1
                        }

                    }
                });
            });
            
            //console.log(dictionaryTagChangesAux);
            setDictionaryTagChanges(dictionaryTagChangesAux)
        }
    }, [listJsonLogs])// eslint-disable-line react-hooks/exhaustive-deps

    //Linked datatable
    useEffect(() => {
        if (dictionaryTagChanges !== null) {
            //console.log(dictionaryTagChanges);
            const myTable = document.getElementById('tagsChangesTable');
            if (myTable) {
                // Customise our labels
                var labelData = {
                    placeholder: "Buscar...",
                    perPage: "{select} Registros por página",
                    noRows: "No hay datos que mostrar",
                    info: "Mostrando de {start} a {end} de los {rows} registros (Página {page} de {pages})"
                };
                let datatableAux = new DataTable(myTable, {
                    labels: labelData,
                    header: true,
                });
                setDataTable(datatableAux)
            }
        }
    }, [dictionaryTagChanges])

    function downloadTableAsCSV(dataTable) {
        // Export the current page as a .csv file
        if (dataTable !== null) {
            dataTable.export({
                type: "csv",
                filename: "tabla-cambios-etiquetas",
                //selection: dataTable.currentPage
            });
        }
    }
    return (
        <Fragment>
            { dictionaryTagChanges && (
                <div className="card p-0">
                    <div className="card-header text-center">
                        <h4>
                            Cambios de etiquetas &nbsp;&nbsp;
                        <button type="button" className="btn btn-outline-success"
                                onClick={() => downloadTableAsCSV(dataTable)}
                            >
                                <CloudDownIconSVG />
                            </button>
                        </h4>
                    </div>
                    <table id='tagsChangesTable' className='table text-left table-hover dataTable'>
                        <thead>
                            <tr>
                                <th>Inicial</th>
                                <th>Final</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(dictionaryTagChanges).map((previousTag, index) => (
                                    Object.keys(dictionaryTagChanges[previousTag]).map((currentTag, index) => (
                                        <tr key={index}>
                                            <td>{previousTag}</td>
                                            <td>{currentTag}</td>
                                            <td>{dictionaryTagChanges[previousTag][currentTag]}</td>
                                        </tr>
                                    ))
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            )
            }
        </Fragment>
    )
}
