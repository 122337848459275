import { fabric } from "fabric";
import { PercepthorArticle } from '../models/PercepthorArticle.js'
import {SmallNoty, ConfirmActionMessage} from './message'

export const ENFASIS_COLOR = [153,255,51]
export let COLOR_TRANSPARENCY = 0.3

export function setCOLOR_TRANSPARENCY(value) {
    COLOR_TRANSPARENCY = value
}

//Define frame color and style for fabric.ActiveSelection
fabric.ActiveSelection.prototype.transparentCorners = false;
fabric.ActiveSelection.prototype.cornerColor = `rgba(${ENFASIS_COLOR[0]},${ENFASIS_COLOR[1]},${ENFASIS_COLOR[2]},1)`;
fabric.ActiveSelection.prototype.cornerStyle = 'rect';

/**
 * Delete canvas' active(s) object(s)
 * @return {void}  Void
 */
export function deleteActiveObject(canvas, transform) {
    var object = null
    if(canvas)
        object = canvas.getActiveObject()
        
    if (object){
        canvas.discardActiveObject();//Para que no queden los marcadores...
        if (object._objects) {
            ConfirmActionMessage.fire({
                icon: 'warning',
                title: 'Advertencia!',
                text: "Desea eliminar los artículos seleccionados?, no podran ser recuperados!",
            }).then((result) => {
                if (result.isConfirmed) {
                    object._objects.forEach(element => {
                        if(element.result !== null){// It's from file .res
                            canvas.listPercepthorArticlesDeleted.push(element)
                        }
                        canvas.remove(element);
                    });
                    SmallNoty('success', 'Los artículos han sido eliminados.')
                    canvas.customRequestRenderAll();
                }
            })
            
        } else {
            if (object.result !== null) {// It's from file .res
                canvas.listPercepthorArticlesDeleted.push(object)
            }
            canvas.remove(object);
            SmallNoty('success', 'El articulo ha sido eliminado.')
        }
        canvas.customRequestRenderAll();
    }else {
        SmallNoty('warning','Para eliminar debe seleccionar uno(s) articulo(s)')
    }
}

/**
 * Lock and unlock canvas' active(s) object(s)
 * @return {void}  Void
 */
export function lock_unlock_ActiveObject(canvas, estado) {

    var object = null
    if(canvas)
        object = canvas.getActiveObject()
        
    if (object) {
        if (object._objects) {
            object._objects.forEach(element => {
                element.lockMovementX = estado;
                element.lockMovementY = estado;
                element.lockScalingX = estado;
                element.lockScalingY = estado;
            });
        } else if (object) {
            object.lockMovementX = estado;
            object.lockMovementY = estado;
            object.lockScalingX = estado;
            object.lockScalingY = estado;
        }
        let cadAux;
        estado ? (cadAux = 'bloqueado(s)') : (cadAux = 'desbloqueado(s)')
        SmallNoty('success', `Articulo(s) ha(n) sido ${cadAux}.`)
        canvas.requestRenderAll();
    }else{
        SmallNoty('warning','Para bloquear/desbloquear debe seleccionar uno(s) articulo(s)')
    }
}

/**
 * Clone canvas' active(s) object(s) and added to it
 * @return {void}  Void
 */
export function cloneActiveObject(canvas) {
    var object = null
    if(canvas)
        object = canvas.getActiveObject()
        
    if (object) {
        canvas.discardActiveObject()
        if (object._objects) { //If are multiple objects
            let listNewObjects = []
            //Cloned one by one object
            let cont = 1
            let epoch = Date.parse(new Date())
            object.forEachObject(function (obj) {
                if (obj.type === 'percepthorArticle') {
                    let newArticle = obj.getCloneObject(obj, epoch + (cont+=1))
                    canvas.add(newArticle);
                    listNewObjects.push(newArticle)
                }
            });
            //Select new gruop objects
            var sel = new fabric.ActiveSelection(listNewObjects, { canvas: canvas });
            canvas.setActiveObject(sel);
        } else if (object.type === 'percepthorArticle') { //Is only one and percepthorArticle
            let newArticle = object.getCloneObject(object, Date.parse(new Date()))
            canvas.add(newArticle);
            canvas.setActiveObject(newArticle)
        }
        SmallNoty('success', `Articulo(s) ha(n) sido clonados.`)
        canvas.customRequestRenderAll();
    }else{
        SmallNoty('warning','Para clonar debe seleccionar uno(s) articulo(s)')
    }
}

export function calPercepthorArticle(canvas, cal) {
    var object = canvas.getActiveObject()
    if (object) {
        canvas.discardActiveObject();//Para que no queden los marcadores...
        if (object._objects) { //If are multiple objects
            object._objects.forEach(function (obj) {
                if (obj.type === 'percepthorArticle' && obj.result != null) {// Its a result from '.res' file?
                    obj.result.isCorrect = cal
                }
            });

        } else if (object.type === 'percepthorArticle' && object.result != null) { //Is only one and percepthorArticle
            object.result.isCorrect = cal
        }
        let cadAux = cal ? 'Correcto' : 'Incorrecto'
        SmallNoty('success', `Articulo(s) ha(n) sido calificado(s) como: ${cadAux}.`)
        //canvas.renderAll();
    } else {
        SmallNoty('warning','Para calificar debe seleccionar uno(s) artículos(s)');
    }
}

export function printFabricObject(obj) {
    var canvas = obj.canvas;
    if (canvas) {
        //const scale = obj.getObjectScaling();
        var objStrokeWidth = obj.strokeWidth
        var objWidth = Math.abs(obj.aCoords.tl.x - obj.aCoords.tr.x)
        objWidth -= objStrokeWidth
        var objHeigth = Math.abs(obj.aCoords.tl.y - obj.aCoords.bl.y)
        objHeigth -= objStrokeWidth

        console.log('OBJECT')
        console.log("topLeft:  (" + obj.aCoords.tl.x + ', ' + obj.aCoords.tl.y + ')')
        console.log("topRigth: (" + obj.aCoords.tr.x + ', ' + obj.aCoords.tr.y + ')')
        console.log("botLeft:  (" + obj.aCoords.bl.x + ', ' + obj.aCoords.bl.y + ')')
        console.log("botRight: (" + obj.aCoords.br.x + ', ' + obj.aCoords.br.y + ')')
        //console.log("xCenter: "+obj.left);
        //console.log("yCenter: "+obj.top);
        console.log("objWidth: " + objWidth);
        console.log("objHeigth: " + objHeigth);
        console.log("objStrokeWidth: " + objStrokeWidth);
    } else {
        console.log('The object isn\'t inside a canvas, please add object to canvas')
    }
}


/**
 * Hidden for x time PercepthorArticles inside canvas when its Tag are different to param Tag
 * @param {PecepthorCanvas} canvas 
 * @param {Tag} tag
 * @return {void}  Void
 */
export function enfasisObjectTag(canvas, tag, timer){
    let listObjectsToHidden = []
    let listObjectsObjective = []
    let newActiveSel = []

    //Get PercepthorArticle with different Tag
    if(canvas){
        if (canvas._objects) {
            canvas._objects.forEach(element => {
                if (element.type === 'percepthorArticle') {
                    if (element.tag !== tag) {
                        listObjectsToHidden.push(element)
                    }else{
                        listObjectsObjective.push(element)
                    }
                }
            });
            canvas.requestRenderAll();
        }else{
            console.error('No hay articulos en el canvas')
        }
    }
    
    if(listObjectsObjective.length > 0){
        //Adjustment image on canvas
        canvas.setFitZoom()

        //Active listObjectsObjective
        canvas.discardActiveObject()
        newActiveSel = new fabric.ActiveSelection(listObjectsObjective, { canvas: canvas });
        canvas.setActiveObject(newActiveSel);

        //Show PercepthorArticle with same Tag
        if(listObjectsObjective.length > 0){
            listObjectsObjective.forEach(element => {
                element.visible = true
            });
        }

        //Hiden PercepthorArticle with different Tag
        if(listObjectsToHidden.length > 0){
            listObjectsToHidden.forEach(element => {
                element.visible = false
            });
        }

        //Render changes
        canvas.requestRenderAll()

        //After x seconds show again all PercepthorArticles
        setTimeout(() =>{
            if(listObjectsToHidden.length > 0){
                listObjectsToHidden.forEach(element => {
                    element.visible = true

                });
                canvas.requestRenderAll()
            } 
        }, timer);
    }
    
    SmallNoty('info',`Hay [${listObjectsObjective.length}]  ${tag.className}`, timer);
}

// Function that creates a new tag component
export function addPercepthorArticle(canvas, activeTag) {
    if (activeTag && canvas) {
        let article = new PercepthorArticle({
            //Attributes of Fabric.Rect
            left: 150,
            top: 200,
            originX: 'left',
            originY: 'top',
            width: 100,
            height: 100,
            objectCaching: false,
            strokeWidth: 1, //este tambien cuenta en el tamanio del objeto, en with y heigth
            strokeUniform: true,
            fill: `rgba(${activeTag.arrayRGBColor[0]}, ${activeTag.arrayRGBColor[1]}, ${activeTag.arrayRGBColor[2]}, ${COLOR_TRANSPARENCY})`,
            stroke: `rgba(${activeTag.arrayRGBColor[0]}, ${activeTag.arrayRGBColor[1]}, ${activeTag.arrayRGBColor[2]}, 1)`,
            //Extended attributes for PercepthorArticle
            tag: activeTag,
            id: Date.parse(new Date()),
            label: '',
        });
        canvas.add(article);
        canvas.setActiveObject(article)
        canvas.customRequestRenderAll();
        SmallNoty('success','El articulo han sido agregado.')
    } else {
        SmallNoty('error', 'No hay una etiqueta activa')
    }
}

/**
 * Replace PercepthorArticle`s Tag of Active PercepthorArticles for activeTag param
 * @param {PecepthorCanvas} canvas 
 * @param {Tag} activeTag
 * @return {void}  Void
 */
export function switchActiveTag(canvas, activeTag) {
    var objActive = null
    if(canvas)
        objActive = canvas.getActiveObject()
    if (objActive && activeTag) {
        canvas.discardActiveObject()
        if (objActive._objects) { //If are multiple objects         
            // Creates a new list of switched objects
            let listNewObjects = []
            objActive._objects.forEach(function (obj) {
                if (obj.type === 'percepthorArticle') {
                    obj.tag = activeTag
                    obj.fill = `rgba(${activeTag.arrayRGBColor[0]}, ${activeTag.arrayRGBColor[1]}, ${activeTag.arrayRGBColor[2]}, ${COLOR_TRANSPARENCY})`
                    obj.stroke = `rgba(${activeTag.arrayRGBColor[0]}, ${activeTag.arrayRGBColor[1]}, ${activeTag.arrayRGBColor[2]}, 1)`

                    listNewObjects.push(obj)
                }
            });
            // Selects the currect list of selected tags
            var sel = new fabric.ActiveSelection(listNewObjects, { canvas: canvas });
            canvas.setActiveObject(sel);
            SmallNoty('success', `Articulos cambiados a [ ${activeTag.className} ]`);

        } else if (objActive.type === 'percepthorArticle') { //Is only one percepthorArticle
            objActive.tag = activeTag
            objActive.fill = `rgba(${activeTag.arrayRGBColor[0]}, ${activeTag.arrayRGBColor[1]}, ${activeTag.arrayRGBColor[2]}, ${COLOR_TRANSPARENCY})`
            objActive.stroke = `rgba(${activeTag.arrayRGBColor[0]}, ${activeTag.arrayRGBColor[1]}, ${activeTag.arrayRGBColor[2]}, 1)`

            canvas.setActiveObject(objActive)
            SmallNoty('success', `Articulo cambiado a [ ${activeTag.className} ]`);
        }
        canvas.customRequestRenderAll();
    } else {
        SmallNoty('warning', 'Debe seleccionar un articulo y tener una etiqueta activa');
    }
}

/**
 * Lock and unlock canvas' all PercepthorArticles
 * @param {Fabric.Canvas} canvas
 * @param {Boolean} state
 * @return {void}  Void
 */
export function lock_unlock_AllPercepthorArticles(canvas, state) {
        
    if (canvas?.getObjects()) {
        canvas.getObjects().forEach(obj => {
            if((obj.type === 'percepthorArticle')){
                obj.lockMovementX = state;
                obj.lockMovementY = state;
                obj.lockScalingX = state;
                obj.lockScalingY = state;
            }
        });
        let cadAux;
        state ? (cadAux = 'bloqueados') : (cadAux = 'desbloqueados')
        SmallNoty('success', `Todos los Articulo ha sido ${cadAux}.`)
        canvas.requestRenderAll();
    }else{
        SmallNoty('warning','No hay articulos que bloquear/desbloquear')
    }
}