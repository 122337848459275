import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch }  from 'react-router-dom';

//Components
import Landing from './components/Automata/Landing';
import QAReport from './components/Reports/QAReport';
import YoloConverter from './components/YoloConverter/YoloConverter';
import AutomataConverter from './components/AutomataConverter/AutomataConverter';
import ResConverter from './components/ResConverter/ResConverter';
import ExifAutoremoveAutorotate from './components/Exif/ExifAutoremoveAutorotate';


class App extends Component {

	render () {
		return (
			<Router>
				<div className="App">
				<Switch>
					{/* Main */}
					<Route exact path='/'>
						<Landing title={'Tagger'}/>
					</Route>

					{/* Reports Quality */}
					<Route exact path='/qa_report'>
						<QAReport title={'QA Report'}/>
					</Route>
					
					{/* Yolo format converter */}
					<Route exact path='/yolo_converter'>
						<YoloConverter title={'Yolo Converter'}/>
					</Route>
					
					{/* AutomataQA format converter */}
					<Route exact path='/automata_converter'>
						<AutomataConverter title={'Automata Converter'}/>
					</Route>

					{/* ResJson converter to Res */}
					<Route exact path='/resjson_converter'>
						<ResConverter title={'ResJSON Converter'}/>
					</Route>

					{/* Exif AutoRemove and AutoRotate images */}
					<Route exact path='/exif_autorotate'>
						<ExifAutoremoveAutorotate title={'Exif AutoRemoveRotate'}/>
					</Route>
					
					{/* <Route exact path='/404' component={ NotFound } />
					<Redirect to="/404" /> */}
				</Switch>
				</div>
			</Router>
		);
	}

}

export default App;