import React, { Fragment } from 'react'

export default function PreviewFilesView(props) {
    const listContentResFiles = props.listContentResFiles
    const listContentYoloFiles = props.listContentYoloFiles

    const index = 0

    let contentHTML = null


    if (listContentResFiles === null || listContentYoloFiles === null){
        contentHTML = ''
    }else{
        contentHTML = (
            <Fragment>
                <h5 className='text-center'>Vista previa formatos</h5>
                <div className='row'>
                    <div className='col-6'>
                        Formato YOLO
                </div>
                    <div className='col-6'>
                        Formato AutomataQA
                </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <textarea className="form-control rounded-0" rows="7" readOnly
                            value={listContentYoloFiles[index]}
                        >
                        </textarea>
                    </div>
                    <div className='col-6'>
                        <textarea className="form-control rounded-0" rows="7" readOnly
                            value={listContentResFiles[index]}
                        >
                        </textarea>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        contentHTML
    )
}
