import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import './WorkPlace/workPlace.css'
import { PercepthorCanvas } from '../../models/PerceptorCanvas.js'
import { ReactComponent as DownloadIconSVG } from '../../static/icons/download.svg'
export const Canvas = (props) => {
  
    let { canvas, setCanvas, setTriggerUpdateQuantityTags} = props
    
    useEffect(() => {
        const localCanvas = new PercepthorCanvas('canvas_automata')
        localCanvas.hoverCursor = 'default';
        localCanvas.stopContextMenu = true;
        localCanvas.fireRightClick = true;
        localCanvas.activeTag = null;
        localCanvas.setTriggerUpdateQuantityTags = setTriggerUpdateQuantityTags
        setCanvas(localCanvas);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    //https://stackblitz.com/edit/react-w2v97x?file=index.js
    function useCanvasSize() { //US hoook
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                let newWidth = document.getElementById("divCanvas").offsetWidth - 25
                let newHeight = document.getElementById("divCanvas").offsetHeight - 25
                setSize([newWidth, newHeight]);
            }
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, []);
        if(canvas){
            canvas.setDimensions({
                width: size[0],
                height: size[1]
            });
        }
        return size;
    }

    const [widthCanvas, heightCanvas] = useCanvasSize(); //It works as EventListener for rezizing canvas

    return(
        <Fragment>
            <canvas id="canvas_automata"></canvas>
            {
                canvas?.percepthorImage && 
                <Fragment>
                    <small className='m-0 text-muted'>
                        #{canvas.percepthorImage?.id} &nbsp; {canvas.percepthorImage?.name}.{canvas.percepthorImage?.extension} &nbsp; {canvas.percepthorImage?.width}x{canvas.percepthorImage?.height} &nbsp; 
                    </small>
                    <small className='m-0 text-muted' role="button" onClick={() => { canvas.downloadAsImage()}}
                        data-toggle="tooltip" data-placement="bottom" title="Guardar el lienzo como una imagen"
                    >
                        <DownloadIconSVG/>
                    </small>
                </Fragment>
            }
            <p hidden> {widthCanvas}x{heightCanvas}</p>
        </Fragment>
    );
}
export default Canvas;