/**
 * Return extension of filename
 * @param  {String} filename filename, ej: 'julio.txt, 'hola.jpg'
 * @return {String} Extension, ej: 'txt', 'jpg', 'res'
 */
export function getFileExtension(filename){
    let aux = filename.split('.')
    return aux[aux.length - 1]
}

/**
 * Return filename without extensions
 * @param  {String} filename filename, ej: 'julio.txt, 'hola.jpg'
 * @return {string} Name, without extensions, ej: 'julio', 'hola'
 */
export function getFileNameWithoutExtensions(filename){
    let aux = filename.split('.')
    return aux[0]
}