import React, { useState, useEffect, Fragment } from 'react'
import './LeftSideBar/sidebar.css'
import Tag from './Tag'

//Vendors
import Swal from 'sweetalert2'
//Helpers
import { setCOLOR_TRANSPARENCY } from '../../helpers/fabricJsCustom'
import { SmallNoty } from '../../helpers/message'
// Icons for title labels
import { ReactComponent as PinIcon } from '../../static/icons/pin-angle.svg'
import { ReactComponent as EyeIcon } from '../../static/icons/eye.svg'
import { ReactComponent as ClosedEyeIcon } from '../../static/icons/eye-slash-fill.svg'
import { ReactComponent as SearchIcon } from '../../static/icons/search.svg'
import { ReactComponent as XSquareIcon } from '../../static/icons/x-square.svg'
// Variables
import { SHOW_ARTICLES_LABELS, setSHOW_ARTICLES_LABELS } from '../../models/PercepthorArticle'

function RightSideBar(props) {

    const canvas = props.canvas
    const listTags = props.listTags
    const activeTag = props.activeTag
    const setActiveTag = props.setActiveTag
    const triggerUpdateQuantityTags = props.triggerUpdateQuantityTags
    const setTriggerUpdateQuantityTags = props.setTriggerUpdateQuantityTags

    const [searchWordTag, setSearchWordTag] = useState("")
    const [checkVisibleAllTags, setCheckVisibleAllTags] = useState(true)
    const [searchDisplayList, setSearchDisplayList] = useState([])
    const [lastCheckTag, setLastCheckedTag] = useState(null)
    const [totalArticles, setTotalArticles] = useState(0)

    useEffect(() => {
        setSearchDisplayList(listTags)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Function that modifies the content of the tags table with wathever is written on the searchbar
    useEffect(() => {
        let listWords = searchWordTag.split(' ')
        if (searchWordTag.length > 0) { //If is something writen on the list, it will be updated according to the letters that match with the writen word
            let listTagsAux = listTags
            listWords.forEach(word => {
                listTagsAux = filterTagList(listTagsAux, word.toLowerCase())
            });
            listTagsAux = shortTagList(listTagsAux)
            setSearchDisplayList(listTagsAux)
        
        } else { //If not, display the old list
            setSearchDisplayList(shortTagList(listTags))
        }
    }, [searchWordTag])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if( canvas ){
            let cont = 0
            canvas.getObjects().forEach(function (obj) {
                if (obj.type === 'percepthorArticle') {
                    cont++
                }
            });
            setTotalArticles(cont)
        }
    }, [triggerUpdateQuantityTags]) // eslint-disable-line react-hooks/exhaustive-deps

    function shortTagList(listTags) {
        return listTags.sort((a, b) => a.className.localeCompare(b.className))
    }

    function filterTagList(listTags, word) {
        return listTags.filter(tag => tag.className.toLowerCase().indexOf(word) > -1)
    }

    function changeCOLOR_TRANSPARENCY(canvas) {
        Swal.fire({
            title: "Ajuste de trasparencia!",
            text: "Transparencia de color para los artículos",
            input: 'range',
            inputValue: 0.5,
            inputAttributes: {
                min: 0,
                max: 1,
                step: 0.05
            },
        }).then((result) => {
            if (result.value && canvas) {
                setCOLOR_TRANSPARENCY(Number(result.value))

                canvas.getObjects().forEach(function (obj) {
                    if (obj.type === 'percepthorArticle') {
                        obj.refreshColor()
                    }
                });
                canvas.requestRenderAll()
            }
        });
    }

    function toggleShowHiddenPercpethorArticleLabels(canvas) {
        setSHOW_ARTICLES_LABELS(!SHOW_ARTICLES_LABELS)
        let contentNoty = "Etiquetas en los artículos "
        contentNoty += SHOW_ARTICLES_LABELS ? "Visibles" : "Ocultas"
        SmallNoty('info', contentNoty)
        canvas.requestRenderAll()
    }

    return (
        <Fragment>
            {/* ActiveTag*/}
            <div className='row p-1 align-middle' style={{ height: '8%' }}>
                {activeTag
                    ?   
                        <h5 className='text-white m-0 pt-2'>
                            <PinIcon /> &nbsp;
                            <button type="button" className='btn' style={{ background: `rgba(${activeTag.arrayRGBColor[0]}, ${activeTag.arrayRGBColor[1]}, ${activeTag.arrayRGBColor[2]}, 1)` }}></button> &nbsp;
                            {activeTag.className} 
                        </h5>
                    :
                        <h6 className='text-white text-center m-0'> <PinIcon /> &nbsp; No hay etiqueta activa</h6>
                }
            </div>
            {/* <SearchBar*/}
            <div className='row p-1 align-middle' style={{ height: '8%' }}>
                <div className="input-group p-0">
                    { searchWordTag.length === 0
                        ?
                            <a className="text-white m-2" href='/#'><SearchIcon /></a>
                        :
                            <a className="text-danger m-2" href='/#' role="button"
                                onClick={() => setSearchWordTag('')}
                            >
                                <XSquareIcon/>
                            </a>
                    }
                    <input type="text" value={searchWordTag} onChange={(e) => { setSearchWordTag(e.target.value)}} className="form-control m-1 p-1" placeholder="Buscar etiqueta" />
                </div>
            </div>
            {/* Header table and used Tags*/}
            <div className="row p-1 scroll border rounded border-light" style={{ height: '44%' }}>
                <table className="table table-dark table-sm table-striped table-hover my-0 ">
                    <thead className="table-dark">
                        <tr>
                            <th scope="col">
                                <PinIcon />
                            </th>
                            <th scope="col" role="button">
                                {checkVisibleAllTags ?
                                    <ClosedEyeIcon onClick={() => setCheckVisibleAllTags(!checkVisibleAllTags)} />
                                    :
                                    <EyeIcon onClick={() => setCheckVisibleAllTags(!checkVisibleAllTags)} />
                                }
                            </th>
                            <th scope="col" role="button"  onClick={() => {changeCOLOR_TRANSPARENCY(canvas)}}>
                                Color
                            </th>
                            <th scope="col">{totalArticles < 1 ? "Ɛ" : `[${totalArticles}]`}</th>
                            <th scope="col" className="text-start" role="button"
                                onClick={() => { toggleShowHiddenPercpethorArticleLabels(canvas) }}
                            >
                                Etiquetas
                            </th>
                        </tr>
                    </thead>
                    {   
                        (searchWordTag.length === 0) 
                        ?   
                            listTags.sort((a, b) => a.className.localeCompare(b.className)).map((tag, index) => (
                                <Tag
                                    key={tag.id.toString()}
                                    canvas={canvas}
                                    tag={tag}
                                    activeTag={activeTag}
                                    setActiveTag={setActiveTag}
                                    checkVisibleAllTags={checkVisibleAllTags}
                                    triggerUpdateQuantityTags={triggerUpdateQuantityTags}
                                    setTriggerUpdateQuantityTags={setTriggerUpdateQuantityTags}
                                    type={'tag'}
                                    lastCheckTag = {lastCheckTag}
                                    setLastCheckedTag = {setLastCheckedTag}
                                />
                            ))
                        :
                            searchDisplayList.map((tag, index) => (
                                <Tag
                                    key={tag.id.toString()}
                                    canvas={canvas}
                                    tag={tag}
                                    activeTag={activeTag}
                                    setActiveTag={setActiveTag}
                                    checkVisibleAllTags={checkVisibleAllTags}
                                    triggerUpdateQuantityTags={triggerUpdateQuantityTags}
                                    setTriggerUpdateQuantityTags={setTriggerUpdateQuantityTags}
                                    type={'tag'}
                                    lastCheckTag={lastCheckTag}
                                    setLastCheckedTag={setLastCheckedTag}
                                />
                            ))
                    }
                </table>
            </div>
            {/* Unsued Tags*/}
            <div className='row p-1 text-white' style={{ height: '5%' }}>
                <p className='m-0'>Sin uso</p>
            </div>
            <div className="row p-1 scroll border rounded border-light" style={{ height: '35%' }}>
                <table className="table table-dark table-sm table-striped table-hover my-0">
                    {   
                        (searchWordTag.length === 0) 
                        ?
                            listTags.sort((a, b) => a.className.localeCompare(b.className)).map((tag, index) => (
                                <Tag
                                    key={tag.id.toString()}
                                    canvas={canvas}
                                    tag={tag}
                                    activeTag={activeTag}
                                    setActiveTag={setActiveTag}
                                    checkVisibleAllTags={checkVisibleAllTags}
                                    triggerUpdateQuantityTags={triggerUpdateQuantityTags}
                                    setTriggerUpdateQuantityTags={setTriggerUpdateQuantityTags}
                                    type={'unusedTag'}
                                    lastCheckTag={lastCheckTag}
                                    setLastCheckedTag={setLastCheckedTag}
                                />
                            ))
                        :
                            searchDisplayList.map((tag, index) => (
                                <Tag
                                    key={tag.id.toString()}
                                    canvas={canvas}
                                    tag={tag}
                                    activeTag={activeTag}
                                    setActiveTag={setActiveTag}
                                    checkVisibleAllTags={checkVisibleAllTags}
                                    triggerUpdateQuantityTags={triggerUpdateQuantityTags}
                                    setTriggerUpdateQuantityTags={setTriggerUpdateQuantityTags}
                                    type={'unusedTag'}
                                    lastCheckTag={lastCheckTag}
                                    setLastCheckedTag={setLastCheckedTag}
                                />
                            ))
                    }
                </table>
            </div>

        </Fragment>
    )
}

export default RightSideBar
