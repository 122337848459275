import React, { useState, useEffect } from 'react'
//Icons
import { ReactComponent as BookmarkIcon } from '../../static/icons/bookmark.svg'
import { ReactComponent as TagsIcon } from '../../static/icons/tags.svg'
import { ReactComponent as EyeIcon } from '../../static/icons/eye.svg'
//Models
import { PercepthorArticle } from '../../models/PercepthorArticle.js'
import { LocalFile } from '../../models/LocalFile';
import { Coordinate } from '../../models/Coordinate'
import { Result } from '../../models/Result'
import { PercepthorImage } from '../../models/PercepthorImage';
//Helpers
import { generateImageLog, saveCurrentPercepthorImageResFile, resFileFormatDefault, loadImage, loadExifDataOfImage} from '../../helpers/fileSystemAPI'
import { getFileExtension, getFileNameWithoutExtensions } from '../../helpers/fileExtension'
import { LOCAL_STORAGE, updateLocalStorageLastImageVisited, updateLocalStorageInitTimeInThisImage } from '../../helpers/localStorage'
import { SmallNoty, LoadingMessage} from '../../helpers/message';
import { COLOR_TRANSPARENCY } from '../../helpers/fabricJsCustom'
import Swal from 'sweetalert2'

// https://source.unsplash.com/pWkk7iiCoDM/400x300
function CardImage(props) {

    const imageFileHandler = props.imageFileHandler
    const canvas = props.canvas
    const index = props.index
    const checkIndexImage = props.checkIndexImage
    const setCheckIndexImage = props.setCheckIndexImage
    const dirHandle = props.dirHandle
    const listTags = props.listTags
    const triggerUpdateQuantityTags = props.triggerUpdateQuantityTags
    const setTriggerUpdateQuantityTags = props.setTriggerUpdateQuantityTags
    const resFileFormat = props.resFileFormat

    const [itImageWasVisited, setItImageWasVisited] = useState(false)
    const [itImageHasTags, setItImageHasTags] = useState(false)
    
    useEffect(() => { //If checkout local storage and show last image visited
        const nameLastImageVisited = LOCAL_STORAGE.getItem('nameLastImageVisited');
        if (nameLastImageVisited === imageFileHandler?.name) {
            changeImageInsideCanvas(dirHandle, canvas, imageFileHandler, resFileFormat)
            SmallNoty('info', `Hola de nuevo :)! Ultima imagen visitada: #${index}`)
        }
        async function renderTagIcon(params) {
            let resTags = await itImagesHasArticles(dirHandle, imageFileHandler)
            setItImageHasTags(resTags)
        }
        renderTagIcon(dirHandle, imageFileHandler)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    /**
     * Open res file of this imagen & checkou if have some line
     * @param {FileSystemHandle} dirHandle DirectorySystemHandle
     * @param {FileSystemHandle} imageFileHandler
     * @return {Boolean}  boolean
     */
    async function itImagesHasArticles(dirHandle, imageFileHandler) {
        if (imageFileHandler && dirHandle) {
            try {
                const fullNameThisImage = imageFileHandler?.name
                const fileHandle = await dirHandle.getFileHandle(fullNameThisImage + '.res', { create: false });//Lo creamos si no existe
                const localFile = new LocalFile(fileHandle) //Usamos nuestra clase
                const lines = await localFile.readLinesFile()
                if (lines.length > 0) {
                    return true
                } else {
                    return false
                }
            } catch (error) {
                //console.error(error)
            }
        }
        return false
    }

    /**
     * Show images inside canvas and render PercepthorArticles from its 'res' file
     * @param {FileSystemHandle} dirHandle DirectorySystemHandle
     * @param {percepthorImage} percepthorImage
     * @param {PercepthorCanvas} canvas
     * @param {JSON} resFileFormat result file format JSON
     * @return {Void}  void
     */
    async function showImageInsideCanvas(dirHandle, percepthorImage, canvas, resFileFormat) {
        
        canvas.setPercepthorImage(percepthorImage)
        
        const fullNameThisImage = percepthorImage.name + '.' + percepthorImage.extension
        const fileHandle = await dirHandle.getFileHandle(fullNameThisImage + '.res', { create: true });//Lo creamos si no existe
        const localFile = new LocalFile(fileHandle) //Usamos nuestra clase
        let resFormatTemporaly = null

        if (resFileFormat){ //If charged config.json file
            resFormatTemporaly = resFileFormat
        }else{
            resFormatTemporaly = resFileFormatDefault
            //console.log("Usando formato de defecto", resFormatTemporaly);
        }

        try {
            percepthorImage.listResults = []
            const lines = await localFile.readLinesFile()
            for (let line of lines) {
                if (line !== '') {
                    let aux = line.split(',')
                    let tagObjective = null
                    listTags.forEach(tag => { //Get color of className
                        if (tag.className === aux[resFormatTemporaly.className]) {
                            tagObjective = tag
                        }
                    });
                    let coor = new Coordinate(
                        aux[resFormatTemporaly.id] ,  
                        aux[resFormatTemporaly.area] ,
                        aux[resFormatTemporaly.upper_left_x] ,
                        aux[resFormatTemporaly.upper_left_y] ,
                        aux[resFormatTemporaly.lower_right_x] ,
                        aux[resFormatTemporaly.lower_right_y] ,
                        aux[resFormatTemporaly.probability] )
                    let r = new Result(Number(aux[resFormatTemporaly.id]), tagObjective, coor)
                    if (tagObjective !== null)
                        percepthorImage.listResults.push(r)
                    else{
                        console.error(`No existe la etiqueta [${aux[resFormatTemporaly.className]}]`)
                        SmallNoty('error', `No existe la etiqueta [${aux[resFormatTemporaly.className]}]`)
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
        
        if (percepthorImage.listResults.length !== 0){
            let contador = 0
            percepthorImage.listResults.forEach(result => {
                let [left, top, width, height] = result.originCoordinate.getParamsForFabricPerceptorTag(percepthorImage.width, percepthorImage.height)
                let rgbAux = result.originTag.arrayRGBColor
                let article = new PercepthorArticle({
                    //Attributes of Fabric.Rect
                    left: left,
                    top: top,
                    originX: 'left',
                    originY: 'top',
                    width: width - 1, //Quitamos el ancho del borde (strokeWidth)
                    height: height - 1, //Quitamos el ancho del borde (strokeWidth)
                    objectCaching: false,
                    strokeWidth: 1, //Ancho del borde, este tambien cuenta en el tamanio del objeto, en width y height
                    strokeUniform: true,
                    fill: `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]}, ${COLOR_TRANSPARENCY})`,
                    stroke: `rgba(${rgbAux[0]}, ${rgbAux[1]}, ${rgbAux[2]}, 1)`,
                    //Extended attributes for PercepthorArticle
                    id: result.id,
                    label: '',
                    result: result,
                    tag: result.originTag,
                    rgbColor: rgbAux,
                });
                canvas.add(article);
                contador+=1
            });
            console.log(`Se han cargado [${contador}] artículos desde los archivos del proyecto`)
            SmallNoty('success', `Se han cargado [${contador}] artículos desde los archivos del proyecto`)
        }else{
            console.log('La imagen no cuenta con artículos anteriores')
            SmallNoty('info', `La imagen [#${index}] no cuenta con artículos anteriores`)
        }
        canvas.renderAll();
        
        updateLocalStorageLastImageVisited(percepthorImage.name + '.' + percepthorImage.extension)
        updateLocalStorageInitTimeInThisImage(Date.parse(new Date()))
        setTriggerUpdateQuantityTags(!triggerUpdateQuantityTags)
    }

    /**
     * Load a image from imageFileHandler to set new images and Generate a image log and save changes to 'res' file for current image, then make image change
     * @param {FileSystemHandle} dirHandle DirectorySystemHandle
     * @param {FileHandler} percepimageFileHandlerthorImage
     * @param {PercepthorCanvas} canvas
     * @return {Void}  void
     */
    async function changeImageInsideCanvas(dirHandle, canvas, imageFileHandler, resFileFormat) {
        
        let loadingMessage = LoadingMessage(
            'Por favor espere!',
            `Guardando cambios y cargando nueva imagen`,
            `${imageFileHandler.name}`,
        )
        loadingMessage.fire()

        if (canvas.percepthorImage) { //If there is a current PercepthorImage in PercepthorCanvas
            const fullNameCurrentImage = canvas.percepthorImage.name + '.' + canvas.percepthorImage.extension
            canvas.discardActiveObject();
            canvas.requestRenderAll();
            await generateImageLog(dirHandle, fullNameCurrentImage, canvas)
            await saveCurrentPercepthorImageResFile(dirHandle, fullNameCurrentImage, canvas, resFileFormat)
            canvas.removePercepthorArticles();
            canvas.listPercepthorArticlesDeleted = []
        }

        setCheckIndexImage(index)
        setItImageWasVisited(true)
        let resTags = await itImagesHasArticles(dirHandle, imageFileHandler)
        setItImageHasTags(resTags)

        if (imageFileHandler){
            let fileAux = new LocalFile(imageFileHandler)
            const fileData = await fileAux.getTheFile();
            
            //Read Exif Data
            let exifDataImage = await loadExifDataOfImage(fileData)
            if (exifDataImage?.Orientation){
                SmallNoty('error', `La imagen [${fileAux.name}] tiene Exif de Orientación, reportalo con el administrador del proyecto`, 30000)
            }

            let imageObj = await loadImage(fileData)

            if (imageObj !== null) {
                let percepthorImageAux = new PercepthorImage(imageObj)
                percepthorImageAux.set({
                    id: index,
                    image: imageObj,
                    name: getFileNameWithoutExtensions(fileAux.name),
                    extension: getFileExtension(fileAux.name),
                    width: imageObj.width,
                    height: imageObj.height,
                })
                await showImageInsideCanvas(dirHandle, percepthorImageAux, canvas, resFileFormat)
            }else{
                SmallNoty('error', `No se pudo cargar la imagen ${fileAux.name}`)
            }
        }else{
            SmallNoty('error', `No se existe la imagen`)
            console.error('No se existe la imagen ', imageFileHandler )
        }
        Swal.close();//close all swal

    }

    return (
        <div className="row">
            <div className="card m-1 mb-3 border border-light" style={{ backgroundColor: '#2c3034' }}>
                {/*<img className="img-thumbnail" src={percepthorImage.image.src} alt="" onClick={() => changeImageInsideCanvas(dirHandle, canvas, percepthorImage, resFileFormat)}/>*/}
                <div className="card-body text-light p-2 text-center" role="button"
                    onClick={() => changeImageInsideCanvas(dirHandle, canvas, imageFileHandler, resFileFormat)}
                >
                    <span> #{index} &nbsp;&nbsp;&nbsp;</span>
                    {(itImageHasTags === true) && 
                         <span style={{ color: 'rgba(51, 152, 255, 1)' }}> <TagsIcon/> &nbsp;&nbsp;&nbsp;</span>
                    }
                    {(itImageWasVisited === true) && 
                        <span style={{ color: 'rgba(255, 134, 51, 1)' }}> <EyeIcon/> &nbsp;&nbsp;&nbsp;</span>

                    }
                    {(checkIndexImage === index) && 
                        <span style={{ color: 'rgba(153, 255, 51, 1)' }}> <BookmarkIcon /> </span>

                    }
                </div>
                <div className="card-footer p-2">
                    <small className="text-muted">{imageFileHandler?.name}</small>
                </div>
            </div >
        </div>  
    )// eslint-disable-next-line
}

export default CardImage
