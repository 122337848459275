import { useRef, useEffect } from 'react'

function useDocumentTitle(title, prevailOnUnmount = false) {

    const defaultTitle = useRef(document.title);

    useEffect(() => {
        if(title !== undefined){
            document.title = defaultTitle.current + ' | ' + title;
        }
    }, [title]);

    useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.title = defaultTitle.current;
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
}

export default useDocumentTitle