import React, { Fragment } from 'react'
import { ReactComponent as FolderOpenIconSVG } from '../../static/icons/folder2-open.svg'
import { ReactComponent as ImagesIconSVG } from '../../static/icons/images.svg'
import { ReactComponent as TagsIconSVG } from '../../static/icons/tags.svg'
import { ReactComponent as FilesIconSVG } from '../../static/icons/files.svg'

export default function ProyectInfo(props) {

    const dirHandle = props.dirHandle
    const listContentYoloFiles = props.listContentYoloFiles
    const listClasses = props.listClasses
    const listImages = props.listImages
    let contentHTML = null


    if (dirHandle === null || listContentYoloFiles === null || listClasses === null || listImages === null) {
        contentHTML = ''
    } else {
        contentHTML = (
            <Fragment>
                <div className='col-3'>
                    <h5 className='text-center'><FolderOpenIconSVG/> <i>{dirHandle?.name}</i></h5>
                </div>
                <div className='col-3'>
                    <h5 className='text-center'><TagsIconSVG/> Clases: <i>{listClasses.length}</i></h5>
                </div>
                <div className='col-3'>
                    <h5 className='text-center'><ImagesIconSVG/> Imagenes: <i>{listImages.length}</i></h5>
                </div>
                <div className='col-3'>
                    <h5 className='text-center'><FilesIconSVG/> Archivos YOLO txt: <i>{listContentYoloFiles.length}</i></h5>
                </div>
            </Fragment>
        )
    }
    
    return (
        contentHTML
    )
}
