import React, { Fragment, useState } from 'react'
import './workPlace.css'
import LeftSideBar from '../LeftSideBar/LeftSideBar'
import RightSideBar from '../RightSideBar'
import { Canvas } from '../Canvas'
import Header from '../Header';
import version from '../../../version.json';
// Icons
import { ReactComponent as LogoPercepthor } from '../../../static/icons/logo_percpethor.svg'
import { URL_USER_MANUAL } from '../../../static/links'
//Helpers
import { getLocalFiles } from '../../../helpers/fileSystemAPI'

function WorkPlace() {

    const [canvas, setCanvas] = useState(null); //Nunca debe de cambiar el canvas
    const [listLocalFiles, setlistLocalFiles] = useState([])
    const [listTags, setlistTags] = useState([])
    const [listImagesFileHandler, setImagesFileHandler] = useState([])
    const [listResults, setListResults] = useState([])
    const [activeTag, setActiveTag] = useState(null)
    const [dirHandle, setDirHandle] = useState(null)
    const [triggerUpdateQuantityTags, setTriggerUpdateQuantityTags] = useState(false)
    const [resFileFormat, setFileResFormat] =  useState(null)

    /**
     * Get data directory source and set hooks
     * @return {Void}  void
     */
    async function loadProject(setDirHandle, setlistLocalFiles, setlistTags, setImagesFileHandler, setFileResFormat) {
        try {
            let json_obj = await getLocalFiles()
            console.log(json_obj)
            setDirHandle( json_obj['dirHandle'] )
            setlistLocalFiles( json_obj['listLocalFiles'] )
            setlistTags( json_obj['listTags'] )
            setImagesFileHandler( json_obj['listImagesFileHandler'] )
            setFileResFormat( json_obj['fileResFormat'] )
        } catch (error) {
            console.error(error)
        }
    }

    return (
        // <!-- MAIN CONTENT -->
        <div className="container-fluid bg-black" style={{ height: '100vh' }}>   
        {(dirHandle) 
        ?
        <Fragment>
            <div className="row p-2 align-items-center" style={{ height: '13vh' }}>
                {/* HEADER */}
                <Header
                    canvas={canvas}
                    listLocalFiles={listLocalFiles}
                    setlistLocalFiles={setlistLocalFiles}
                    listTags={listTags}
                    setlistTags={setlistTags}
                    listImagesFileHandler={listImagesFileHandler}
                    setImagesFileHandler={setImagesFileHandler}
                    listResults={listResults}
                    setListResults={setListResults}
                    dirHandle={dirHandle}
                    setDirHandle={setDirHandle}
                    setActiveTag={setActiveTag}
                    setFileResFormat={setFileResFormat}
                />
            </div>
            <div className="row p-2">
                {/* IMAGES */}
                <div className="col-lg-2 col-md-2 col-sm-2 text-center" style={{ height: '80vh' }}>
                    <LeftSideBar
                        canvas={canvas}
                        listImagesFileHandler={listImagesFileHandler}
                        dirHandle={dirHandle}
                        listTags={listTags}
                        triggerUpdateQuantityTags={triggerUpdateQuantityTags}
                        setTriggerUpdateQuantityTags={setTriggerUpdateQuantityTags}
                        resFileFormat={resFileFormat}
                    />
                </div>
                {/* CANVAS */}
                <div id='divCanvas' className="col-lg-7 col-md-7 col-sm-7 text-center" style={{ height: '80vh' }}
                    onContextMenu={(e) => e.preventDefault()}
                >
                    <Canvas
                        canvas={canvas}
                        setCanvas={setCanvas}
                        setTriggerUpdateQuantityTags={setTriggerUpdateQuantityTags}
                    />
                </div>
                {/* <!--RightSideBar--> */}
                <div className="col-lg-3 col-md-3 col-sm-3 text-center" style={{ height: '80vh'}}>
                    <RightSideBar
                        canvas={canvas}
                        listTags={listTags}
                        activeTag = {activeTag}
                        setActiveTag = {setActiveTag}
                        triggerUpdateQuantityTags={triggerUpdateQuantityTags}
                        setTriggerUpdateQuantityTags={setTriggerUpdateQuantityTags}
                    />
                </div>
            </div>
        </Fragment>
        :
            // Animation
            <div className="row p-2 justify-content-center text-white text-center" style={{ height: '93vh' }}>
                <div className='col-auto'>    
                    <a href="http://percepthor.com/" target="_blank" rel="noreferrer">
                        <span className="text-white"><LogoPercepthor className="logo m-4" /></span>
                    </a>
                    <h1>Autómata <sup>QA</sup></h1>
                    <br/>
                    <h3 role='button' className="text-secondary text-decoration-underline"
                            onClick={() => loadProject(setDirHandle, setlistLocalFiles, setlistTags, setImagesFileHandler, setFileResFormat)}
                    >
                        Carga un proyecto para comenzar
                    </h3>
                    <br/>
                    <a href='/#' onClick={() => { window.open(URL_USER_MANUAL) }} >
                        <small className="text-secondary text-decoration-underline">
                            Manual de usuario / Ayuda
                        </small>
                    </a>
                </div>
            </div>
        }
        {/*Footer*/}
            <div className="row p-2 text-center text-white" style={{ height: '4vh' }}>
                <p className="m-0 p-0">Copyright &copy; {new Date().getFullYear()} Percepthor - {version.version_name} - {version.version_date}</p>
            </div>
        </div>


    )
}

export default WorkPlace

