import React from 'react'
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2'
export default function ButtonDownload(props) {

    const dirHandle = props.dirHandle
    const zip = props.zip


    function downloadZip(zip, dirHandle) {
        if (zip !== null && dirHandle !== null) {
            Swal.fire({
                title: 'Espere...',
                html: 'Convirtiendo el proyecto',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.showLoading()
                },
                willClose: () => {
                }
            })
            zip.generateAsync({ type: "blob" })
                .then(function (blob) {
                    Swal.close();
                    saveAs(blob, `${dirHandle.name}_AutomataQA.zip`);
                });
        }else{
            console.error("Algo salio mal: ",zip, dirHandle)
        }
    }

    let contentHTML = null


    if (dirHandle === null || zip === null) {
        contentHTML = ''
    } else {
        contentHTML = (
            <button
                className="btn btn-success" type="button"
                onClick={() => downloadZip(zip, dirHandle)}
            >
                Descargar proyecto en formato AutomataQA
            </button>
        )
    }
    
    return (
        contentHTML
    )
}
