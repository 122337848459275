import React from 'react'

function Button(props) {

    const functionToExcecute = props.functionToExcecute;
    const functionToExcecuteRightClick = props.functionToExcecuteRightClick
    const tooltip = props.tooltip

    return (
        <div className="col-sm-4 col-md-2 col-lg-2 p-1">
            <button
                type="button"
                className="btn btn-sm btn-outline-light"
                data-toggle="tooltip" data-placement="bottom" title={tooltip}
                onClick={() => functionToExcecute()}
                onContextMenuCapture={ (e) => {
                    e.preventDefault()
                    if (functionToExcecuteRightClick !== undefined){
                        functionToExcecuteRightClick()
                    }
                }}
            >
                {props.children}
            </button>
        </div>
    )
}

export default Button

