class LocalFile {
    /** @constructor */
    constructor(fileHandle) {
        /**
         * Interface of the File System Access API is an object which represents a file
         * @type {FileSystemFileHandle}
         */
        this.fileHandle = fileHandle;
        /**
         * File's name
         * @type {string}
         */
        this.name = fileHandle.name;
    }

    /**
     * Async: Get file's text content
     * @return {string}  Text content
     */
    async readFile() {
        // Get a object File within fileHandle
        const file = await this.fileHandle.getFile();
        // Get content
        const content = await file.text();
        // Remove '\r' from content
        const textAux = removeCarriageReturn(content)
        // return textAux
        return textAux;
    }

    /**
     * Async: Get file's text content split by '\n'
     * @return {string}  Array string 
     */
    async readLinesFile() {
        // Get a object File within fileHandle
        const file = await this.fileHandle.getFile();
        // Get content
        const content = await file.text();
        // Remove '\r' from content
        const textAux = removeCarriageReturn(content)
        // Split textAux by '\n'
        let lines = textAux.split('\n')
        //Remove empty values
        lines = removeEmptyValues(lines)
        // return arrays with lines
        return lines;
    }

    /**
     * Async: Write text inside file, override previous content
     * @param {string} text Text to write
     * @return {void}
     */
    async writeFile(text) {
        // Create a FileSystemWritableFileStream to write to from fileHandle.
        const writable = await this.fileHandle.createWritable();
        // Remove '\r' from text
        const textAux = removeCarriageReturn(text)
        // Write the contents of the file to the stream.
        await writable.write(textAux);
        // Close the file and write the contents to disk.
        await writable.close();
    }

    //https://developer.mozilla.org/en-US/docs/Web/API/FileSystemFileHandle/getFile
    /**
     * Async: File interface provides information about files and allows JavaScript in a web page to access their content.
     * @return {File} File for HTML
     */
    async getTheFile() {
        // Get File (file contents) from fileHandle.
        const fileData = await this.fileHandle.getFile();
        return fileData
    }
}

/**
 * Remove CarriageReturn from String
 * @param  {String} string  string
 * @return {String} string without '\r'
 */
function removeCarriageReturn(string) {
    let res = string.replace(/\r/g, '');
    res = res.replace(/\r\n/g, '\n');
    return res
}

/**
 * Remove empty values of array. It works with all falsy values like 0, false, null, undefined, '', etc.
 * @param  {Array<String>} array  ['One', 'Two', '', 'Four', '', ''];
 * @return {Array<String>} array ['One', 'Two', 'Four']
 */
function removeEmptyValues(array) {
    let res = null
    res = array.filter(item => item);
    return res
}

export { LocalFile };