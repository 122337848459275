const extensionsImageValid = Object.freeze({
    "png": true, 
    "jpg": true, 
    "jpeg": true,
    "jpe": true,
    "cvg": false,
})

/**
 * Valid a imagen extension usising 'extensionsImageValid' if the extension is in it return true, else return false
 * @param  {string} stringExtension Anything string, ej: 'png', 'jpg', 'jpeg'
 * @return {boolean}  
 */
export function validExtensionImage(stringExtension) {
    
    let r = extensionsImageValid[stringExtension]
    if(r === undefined){
        return false
    }else{
        return r
    }
}

//Example use
//console.log(validExtensionImage('png'))
//console.log(validExtensionImage('txt'))
//console.log(validExtensionImage('jpeg'))
//console.log(validExtensionImage('cvg'))