import React, { useState, useEffect } from 'react'
import { Contextual } from '../../static/vendors/ContextualJS/contextual'
import { SmallNoty } from '../../helpers/message'
import { hexToRgb, rgbToHex } from '../../helpers/colors'
import { enfasisObjectTag } from '../../helpers/fabricJsCustom'
import './LeftSideBar/sidebar.css'
import './WorkPlace/workPlace.css'
export const Tag = (props) => {

    const tag = props.tag
    const canvas = props.canvas
    const activeTag = props.activeTag
    const setActiveTag = props.setActiveTag
    const checkVisibleAllTags = props.checkVisibleAllTags
    const triggerUpdateQuantityTags = props.triggerUpdateQuantityTags
    const type = props.type
    const lastCheckTag = props.lastCheckTag
    const setLastCheckedTag = props.setLastCheckedTag
    
    const [quantityThisTag, setQuantityThisTag] = useState(0)
    const [showThisTag, setShowThisTag] = useState(false)
    const [visibleThisTag, setVisibleThisTag] = useState(true)
    const [triggerChangeColorThisTag, setTriggerChangeColorThisTag] = useState(true)

    //Hidden/show PercepthorArticles inside Canvas if its Tag is this props.tag
    useEffect(() => {
        tag.isVisible = visibleThisTag //important
        if (canvas._objects) {
            canvas.discardActiveObject();
            canvas._objects.forEach(element => {
                if (element.type === 'percepthorArticle') {
                    if (element.tag === tag) {
                        element.visible = tag.isVisible
                    }
                }
            });
            canvas.requestRenderAll();
        }
    }, [visibleThisTag]) // eslint-disable-line react-hooks/exhaustive-deps

    //When user click on eye icon
    useEffect(() => {
        setVisibleThisTag(checkVisibleAllTags) //important
    }, [checkVisibleAllTags]) // eslint-disable-line react-hooks/exhaustive-deps

    //Calculate the number of PercpethorArticles that use this props.tag
    useEffect(() => {
        //console.log('calculando cantidad triggerUpdateQuantityTags')
        let quantityAux = 0
        if(canvas){
            canvas.getObjects().forEach(function (obj) {
                if (obj.type === 'percepthorArticle' && obj.tag === tag) {
                    quantityAux += 1
                }
            });
        }
        setQuantityThisTag(quantityAux)
        
        if (quantityAux > 0 && type === 'tag') { //If type Tag is 'tag' and quantityAux > 0 show
            setShowThisTag(true)
        }
        else if (quantityAux === 0 && type === 'unusedTag') //If type Tag is 'unusedTag' and quantityAux == 0 show
        {
            setShowThisTag(true)
        }
        else{
            setShowThisTag(false)
        }

    }, [triggerUpdateQuantityTags])// eslint-disable-line react-hooks/exhaustive-deps

    //Change activeTag hook
    function changeActiveTag(tag, setActiveTag) {
        setActiveTag(tag)
        canvas.activeTag = tag //JULIO DIAZ
        SmallNoty('info', `Nueva etiqueta activa [ ${tag.className} ]`)
    }
    
    function showTagImageExample(event, tag) {
        event.preventDefault()
        let contentHTML = ` <div>
                                <div class="caption text-center">
                                    <p>
                                        <button type="button" class="btn" style="background: rgba(${tag.arrayRGBColor[0]}, ${tag.arrayRGBColor[1]}, ${tag.arrayRGBColor[2]})"></button>
                                        ${tag.className}
                                    </p>
                                </div>
                                <img src="${tag.urlImageExample}" alt=" Imagen de ${tag.className}" class="img-fluid">
                            </div>`
        new Contextual({
            event: event,
            isSticky: false,
            width: '250px',
            items: [{ type: 'custom', markup: contentHTML },]
        });
        
    }

    function changeColorTag(e, canvas,tag) {
        let hex = e.target.value
        let rgb = hexToRgb(hex)
        let arrayRGBColorAux = [rgb.r, rgb.g, rgb.b]
        tag.arrayRGBColor = arrayRGBColorAux

        if (canvas) { //Update articles color
            canvas.getObjects().forEach(function (obj) {
                if (obj.type === 'percepthorArticle' && obj.tag === tag) {
                    obj.refreshColor()
                }
            });
        }
        canvas.requestRenderAll()
    }

    return (
        <tbody className="align-middle">
            {showThisTag &&
                <tr>
                    <th>
                        <input className="form-check-input position-static"
                            type="radio" name="tagRadio"
                            value={tag.className}
                            checked={activeTag === tag ? true : false}
                            onChange={() => changeActiveTag(tag, setActiveTag)}
                        />
                    </th>
                    <td>
                        <input className="form-check-input position-static"
                            type="checkbox"
                            checked={visibleThisTag}
                            onChange={() => { setVisibleThisTag(!visibleThisTag) }
                            }
                        />
                    </td>
                    <td>
                        <input type="color" className="input-color" value={rgbToHex(tag.arrayRGBColor[0], tag.arrayRGBColor[1], tag.arrayRGBColor[2])}
                            onChange={(e) => { 
                                changeColorTag(e, canvas, tag)
                                setTriggerChangeColorThisTag(!triggerChangeColorThisTag)
                            }}
                            placeholder={String(triggerChangeColorThisTag)} /*Force update dom element*/
                            >
                        </input>                    
                    </td>
                    <td
                        style={lastCheckTag === tag ? { color: 'rgba(153, 255, 51, 1)' } : {}}
                    >
                        {(quantityThisTag > 0) ? <b>{quantityThisTag}</b> : <b>&nbsp;</b>}
                    </td>
                    <td className="m-0 p-0" role="button"
                        style={lastCheckTag === tag ? { color: 'rgba(153, 255, 51, 1)' } : {}}
                    >
                        <p className="fs-7 m-0 p-0 text-start"
                            onClick={() => {
                                enfasisObjectTag(canvas, tag, 2500)
                                setLastCheckedTag(tag)
                            }}
                            onContextMenu={(event) => {
                                showTagImageExample(event, tag)
                            }}
                        >
                            {tag.className}
                        </p>
                    </td>
                </tr>
            }
        </tbody>
    );
};

export default Tag;