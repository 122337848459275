import Swal from 'sweetalert2'
import Noty from "noty";

/**
 * Shows a small message with noty
 * @param  {String} type It can be: success, error, info or warning
 * @param  {Number} timeout timeout miliseconds
 * @return {void}   It don't return anything
 */
export function SmallNoty(type, text, timeout=2600) {
    
    let tittle = ''
    switch (type) {
        case 'success':
            tittle = 'Correcto'
            break;
        case 'error':
            tittle = 'Error'
            break;
        case 'info':
            tittle = 'Info'
            break;
        case 'warning':
            tittle = 'Advertencia'
            break;
        default:
            break;
    }

    new Noty({
        text: `<b>${tittle}</b> <br> ${text}`,
        layout: "bottomRight",
        theme: "bootstrap-v4",
        type: type,
        progressBar: true,
        timeout: timeout
    }).show();
}

/**
 * Shows a message with two buttons for cancel or confirm some action with sweetalert2
 * @param  {string} icon It can be: success, error or warning
 * @param  {string} title Title to show
 * @param  {string} text Text to show
 * @return {void}   It don't return anything
 */
export const ConfirmActionMessage = Swal.mixin({
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonColor: '#00920F',
    cancelButtonColor: '#D60000',
    confirmButtonText: 'Si, realizar!',
    cancelButtonText: 'Cancelar',
})

/**
 * Shows a loading mensagge,you can show bold text using changeContentLoadingMessage(), its usesweetalert2
 * @param  {string} title Title to show
 * @param  {string} subtitle Subtitle
 * @param  {string} text Text
 * @return {void}   It don't return anything
 */
export const LoadingMessage = (title,subtitle,text,footer='') => Swal.mixin({
    title: title,
    html: `${subtitle} <br/><br/> <b>${text}</b> <br/><br/> <small>${footer}</small> <br/>`,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    didOpen: () => {
        Swal.showLoading()
    },
    willClose: () => {
    }
})

/**
 * Change loadingMensagge's text
 * @param  {string} newText new text to show
 * @return {void}   It don't return anything
 */
export function changeTextLoadingMessage(newText){
    const content = Swal.getHtmlContainer()
    if (content) {
        const b = content.querySelector('b')
        if (b) {
            b.textContent = newText
        }
    }
}
/**
 * Change loadingMensagge's text
 * @param  {string} newText new text to show
 * @return {void}   It don't return anything
 */
export function changeFooterLoadingMessage(newFooter){
    const content = Swal.getHtmlContainer()
    if (content) {
        const f = content.querySelector('small')
        if (f) {
            f.textContent = newFooter
        }
    }
}

/*//Examples use
    SmallNoty('success', `Se han cargado [${contador}] artículos desde los archivos del proyecto`)
    SmallNoty('info', `La imagen no cuenta con artículos anteriores`)
    SmallNoty('error', `mensaje de error`)

    ConfirmActionMessage.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: "Desea eliminar los objetos seleccionados?, no podran ser recuperados!",
    }).then((result) => {
        if (result.isConfirmed) {
            object._objects.forEach(element => {
                canvas.remove(element);
            });
            SmallMessage.fire({
                icon: 'success',
                title: 'Correcto!',
                text: 'Los objetos han sido eliminados.',
            })
        } 
    })

    let loadingMessage = LoadingMessage(
            'Por favor espere!',
            'Cargando archivos del proyecto', 
            'hola'
        )
    loadingMessage.fire()
                 
*/
