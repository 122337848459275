import React, {useState, Fragment } from 'react'
import JSZip from 'jszip'
import Footer from '../Footer'
//Models
import { LocalFile } from '../../models/LocalFile';
//Helpers
import { validExtensionImage } from '../../helpers/validExtensionImage'
import { getFileExtension, getFileNameWithoutExtensions } from '../../helpers/fileExtension'
import { verifyPermissionHandle, resFileFormatDefault} from '../../helpers/fileSystemAPI'
import { LoadingMessage, changeTextLoadingMessage, changeFooterLoadingMessage } from '../../helpers/message'
import useDocumentTitle from '../../helpers/useDocumentTitle'
//Components
import PreviewFilesView from './PreviewFilesView';
import DirClasses from './DirClasses';
import ButtonDownload from './ButtonDownload'
import ProyectInfo from './ProyectInfo'
//Vendors
import Swal from 'sweetalert2'

function YoloConverter(props) {
    const [dirHandle, setDirHandle] = useState(null)
    const [listContentResFiles, setListContentResFiles] = useState(null)
    const [listContentYoloFiles, setListContentYoloFiles] = useState(null)
    let resFileFormatProyect = null
    const [dirClasses, setDirClasses] = useState(null)
    const [listImages, setListImages] = useState(null)
    const [zip, setZip] = useState(null)
    const formatResFileRes = '.res'

    useDocumentTitle(props.title);

    function getContentYOLONamesFile(lines) {
        let newContent = ''
        lines.forEach(line => {
            if(line!== ''){
                let aux = line.split(',')
                newContent += aux[1] + '\n'
            }
        });
        return newContent
    }

    function getDirClases(lines) {
        let dir = {}
        let index = 0
        lines.forEach(line => {
            if (line !== '') {
                let aux = line.split(',')
                dir[aux[1]] = index
                index+=1
            }
        });
        return dir
    }

    function getContentFormatYOLOFromResFile(dirClasses, lines, resFileFormat, nameFile) {
        let newContent = ''
        lines.forEach(line => {
            let aux = line.split(',')
            if (line !== '' && 
                aux[resFileFormat['className']] !== undefined &&
                aux[resFileFormat['center_x']]  !== undefined &&
                aux[resFileFormat['center_y']]  !== undefined &&
                aux[resFileFormat['width']]     !== undefined &&
                aux[resFileFormat['height']]    !== undefined &&
                dirClasses[aux[resFileFormat['className']]] !== undefined
            ) {
                
                let indexClass = dirClasses[aux[resFileFormat['className']]]
                newContent += indexClass + ' '
                newContent += aux[resFileFormat['center_x']] + ' '
                newContent += aux[resFileFormat['center_y']] + ' '
                newContent += aux[resFileFormat['width']] + ' '
                newContent += aux[resFileFormat['height']] + ' '
                newContent += '\n'
            }else{
                console.error(`Una linea del archivo [${nameFile}] no tiene el formato valido, para hacer la conversion`)
            }
        });
        //console.log(newContent)
        return newContent
    }


    async function selectProyectDirectory() {
        let dirHandleAux = null
        let dirClassesAux =  null
        let listContentResFilesAux = []
        let listContentYoloFilesAux = []
        let listImagesAux = []
        let zipAux = new JSZip();
        
        try {
            dirHandleAux = await window.showDirectoryPicker();
            await verifyPermissionHandle(dirHandleAux, true)
        } catch (err) {
            console.error(err.name, err.message);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'No se pudo cargar el proyecto'
            });
            return
        }

        let loadingMessage = LoadingMessage(
            'Por favor espere!',
            'Cargando imagenes y etiquetas del proyecto',
            '',
        )
        loadingMessage.fire()

        //Add images and .names file ZIP, and get config json if it exist
        for await (const item of dirHandleAux.values()) {
            try {
                if (item.kind === 'file') {
                    let fileAux = new LocalFile(item)
                    changeTextLoadingMessage(fileAux.name)
                    if (validExtensionImage(getFileExtension(fileAux.name))) { //If is it a Image?
                        const file = await fileAux.getTheFile();
                        listImagesAux.push(file)
                        //https://stuk.github.io/jszip/documentation/api_jszip/file_data.html
                        zipAux.file(file.name, file);
                        
                    }
                    if ('names' === getFileExtension(fileAux.name)) { //if is a tags file
                        const file = await fileAux.getTheFile();
                        let lines = await fileAux.readLinesFile()
                        dirClassesAux = getDirClases(lines)
                        let newContent = getContentYOLONamesFile(lines)
                        zipAux.file(file.name, newContent);
                    }
                    if ('config.json' === fileAux.name) {
                        let content = await fileAux.readFile()
                        let json_obj = JSON.parse(content)
                        resFileFormatProyect = json_obj.resFileFormat
                        console.info(`Archivo de config cargado, formato archivos 'res': `, json_obj.resFileFormat)
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }

        //Create a coord files with YOLO format form '.res' files and dirClasses
        if (dirClassesAux !== null && dirHandleAux !== null){
            let resFileFormatTemp = resFileFormatDefault
            if (resFileFormatProyect !== null){
                resFileFormatTemp = resFileFormatProyect
            }
            let contAux = 0
            for await (const image of listImagesAux) {
                try {
                    contAux += 1
                    changeTextLoadingMessage(image.name)
                    changeFooterLoadingMessage(`${contAux} de ${listImagesAux.length}`)
                    //Get a res file for each image
                    const resFileHandleThisImage = await dirHandleAux.getFileHandle(image.name + formatResFileRes, { create: false })
                    const resFileThisImage = new LocalFile(resFileHandleThisImage)
                    const contentRes = await resFileThisImage.readFile()
                    if (contentRes !== '') {
                        //Read lines
                        const linesRes = await resFileThisImage.readLinesFile()
                        listContentResFilesAux.push(contentRes)
                        //Added new file on yolo formats
                        let newContent = getContentFormatYOLOFromResFile(dirClassesAux, linesRes, resFileFormatTemp, resFileThisImage.name)
                        listContentYoloFilesAux.push(newContent)
                        zipAux.file(getFileNameWithoutExtensions(resFileThisImage.name) + '.txt', newContent);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }
        zipAux.file("Leeme.txt", `Conversion correcta ${new Date()}\n`);
        console.log('dirHandleAux: ',dirHandleAux)
        console.log('dirClassesAux: ', dirClassesAux)
        console.log('listContentResFilesAux: ', listContentResFilesAux)
        console.log('listContentYoloFilesAux: ', listContentYoloFilesAux)
        console.log('zipAux: ', zipAux)
        setDirHandle(dirHandleAux)
        setDirClasses(dirClassesAux)
        setListContentResFiles(listContentResFilesAux)
        setListContentYoloFiles(listContentYoloFilesAux)
        setListImages(listImagesAux)
        setZip(zipAux)
        Swal.close();
    }

    return (
        <Fragment>
            <div className='container'>
                <div className='row m-2'>
                    <h3 className='text-center'>Convertidor proyectos a formato a YOLO</h3>
                </div>
                <div className='row m-2'>
                    <p className='text-center'>Carga un proyecto <b>trabajado en el AutomataQA</b> dando click, verifica la salida y da click en descargar</p>
                </div>
                <div className='row m-2'>
                    <p className='text-center'>Si se uso un <b>formato de archivos res especial</b> para el etiquetado del proyecto es necesario tener, <b>el archivo config.json</b>, si no fue asi ignora este mensaje</p>
                </div>
                <div className='row m-2'>
                    <button
                        className="btn btn-primary" type="button"
                        onClick={() => { selectProyectDirectory() }}
                    >
                        Cargar proyecto
                    </button>
                </div>
                <br />
                {/*Info proyecto*/}
                <div className='row m-2'>
                    <ProyectInfo
                        dirHandle = {dirHandle}
                        listContentResFiles = {listContentResFiles}
                        dirClasses = {dirClasses}
                        listImages = {listImages}
                    />
                </div>
                <br />
                {/*Clases*/}
                <div className='row m-2'>
                    <DirClasses
                        dirClasses={dirClasses}
                    />
                </div>
                <br/>
                {/*Vista previa formatos */}
                <div className='row m-2'>
                    <PreviewFilesView
                        listContentResFiles = {listContentResFiles}
                        listContentYoloFiles = {listContentYoloFiles}
                    />
                </div>
                <br />
                {/*Botton descargar */}
                <div className='row m-2'>
                    <ButtonDownload
                        dirHandle={dirHandle}
                        zip={zip}
                    />
                </div>
            </div>
            <br/>
            <Footer/>
        </Fragment>
    )
}

export default YoloConverter