import React from 'react';

import WorkPlace from './WorkPlace/WorkPlace'
//import Footer from '../Footer'

// Importing bootstrap library.
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import useDocumentTitle from '../../helpers/useDocumentTitle'

function Landing(props)  {

    useDocumentTitle(props.title);

    return (
        <div className='bg-dark'>
            <WorkPlace />
        </div>
    );

}

export default Landing;