import React, { useState, Fragment } from 'react'
import JSZip from 'jszip'
import Footer from '../Footer'
//Models
import { LocalFile } from '../../models/LocalFile';
//Helpers
import { validExtensionImage } from '../../helpers/validExtensionImage'
import { getFileExtension, getFileNameWithoutExtensions } from '../../helpers/fileExtension'
import { verifyPermissionHandle, loadImage} from '../../helpers/fileSystemAPI'
import { roundFloat } from '../../helpers/numbers'
import { SmallNoty, LoadingMessage, changeTextLoadingMessage, changeFooterLoadingMessage } from '../../helpers/message'
import useDocumentTitle from '../../helpers/useDocumentTitle'
//Vendors
import Swal from 'sweetalert2'
//Components
import TableClasses from './TableClasses'
import ProyectInfo from './ProyectInfo'
import PreviewFilesView from './PreviewFilesView';
import ButtonDownload from './ButtonDownload'

function AutomataConverter(props) {

    const formatResFile = '.res'
    const formatYoloFile = '.txt'
    const [listClasses, setListClasses] = useState(null)
    const [dirHandle, setDirHandle] = useState(null)
    const [listContentResFiles, setListContentResFiles] = useState(null)
    const [listContentYoloFiles, setListContentYoloFiles] = useState(null)
    const [listImages, setListImages] = useState(null)
    const [zip, setZip] = useState(null)

    useDocumentTitle(props.title);

    function getNamesFileFromYoloFormat(lines) {
        let newContent = ''
        let index = 0
        lines.forEach(line => {
            if (line !== '') {
                newContent += index + ',' + line + '\n'
                index += 1
            }
        });
        return newContent
    }

    function getListClasesFromYoloFormat(lines) {
        let list = []
        lines.forEach(line => {
            if (line !== '') {
                list.push(line)
            }
        });
        return list
    }


    function getResFormatFromYolo(center_x_relative, center_y_relative, width_relative, height_relative, imgWidth, imgHeight) {
        let center_x_absolute = imgWidth * center_x_relative
        let center_y_absolute = imgHeight * center_y_relative
        let width_absulute = imgWidth * width_relative
        let height_absulute = imgHeight * height_relative

        let upper_left_x_absolute = center_x_absolute - (width_absulute / 2)
        let upper_left_y_absolute = center_y_absolute - (height_absulute / 2)
        let lower_right_x_absolute = center_x_absolute + (width_absulute / 2)
        let lower_right_y_absolute = center_y_absolute + (height_absulute / 2)

        let upper_left_x_relative = roundFloat(upper_left_x_absolute / imgWidth, 4)
        let upper_left_y_relative = roundFloat(upper_left_y_absolute / imgHeight, 4)
        let lower_right_x_relative = roundFloat(lower_right_x_absolute / imgWidth, 4)
        let lower_right_y_relative = roundFloat(lower_right_y_absolute / imgHeight, 4)

        return [upper_left_x_relative, upper_left_y_relative, lower_right_x_relative, lower_right_y_relative]
    }

    function getContentFormatAutomataQAFromYoloLines(listClassesAux, lines, nameFile, image) {
        let newContent = ''
        let contId = 1
        lines.forEach(line => {
            try {
                let aux = line.split(' ')//Split by spaces
                aux = aux.filter(item => item) //Remove empty values
                if (aux.length === 5) {

                    let indexClass = Number(aux[0])
                    let center_x_relative = roundFloat(Number(aux[1]), 4)
                    let center_y_relative = roundFloat(Number(aux[2]), 4)
                    let width_relative = roundFloat(Number(aux[3]), 4)
                    let height_relative = roundFloat(Number(aux[4]), 4)

                    if (listClassesAux[indexClass] !== undefined &&
                        !isNaN(center_x_relative) &&
                        !isNaN(center_y_relative) &&
                        !isNaN(width_relative) &&
                        !isNaN(height_relative)
                    ) {
                        let [upper_left_x_relative, upper_left_y_relative, lower_right_x_relative, lower_right_y_relative] = getResFormatFromYolo(center_x_relative, center_y_relative, width_relative, height_relative, image.width, image.height)

                        let className = listClassesAux[indexClass]
                        newContent += `${contId},`
                        newContent += `${className},`
                        newContent += `0,` //area
                        newContent += `${center_x_relative},`
                        newContent += `${center_y_relative},`
                        newContent += `${upper_left_x_relative},`
                        newContent += `${upper_left_y_relative},`
                        newContent += `${lower_right_x_relative},`
                        newContent += `${lower_right_y_relative},`
                        newContent += `0,` //probability
                        newContent += `${width_relative},`
                        newContent += `${height_relative},`
                        newContent += `\n`

                        contId += 1
                    } else {
                        console.error(`La linea [${contId}] del archivo [${nameFile}] no tiene el formato yolo valido, para hacer la conversion`)
                        SmallNoty('error', `La linea [${contId}] del archivo [${nameFile}] no tiene el formato yolo valido, para hacer la conversion`)
                    }
                }
                else {
                    console.error(`Una linea del archivo [${nameFile}] no tiene los 5 parametros yolo`)
                    SmallNoty('error', `La linea [${contId}] del archivo [${nameFile}] no tiene los 5 parametros yolo`)
                }
            } catch (error) {
                console.error(error)
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Errores al convertir el proyecto'
                });
            }
        });
        //console.log(newContent)
        return newContent
    }


    async function selectProyectDirectory() {
        let dirHandleAux = null
        let listClassesAux = null
        let listImagesObjAux = []
        let listContentYoloFilesAux = []
        let listContentResFilesAux = []
        let zipAux = new JSZip();

        try {
            dirHandleAux = await window.showDirectoryPicker();
            await verifyPermissionHandle(dirHandleAux, true)
        } catch (err) {
            console.error(err.name, err.message);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'No se pudo cargar el proyecto'
            });
            return
        }

        let loadingMessage = LoadingMessage(
            'Por favor espere!',
            'Cargando imagenes y etiquetas del proyecto',
            '',
        )
        loadingMessage.fire()

        //Add images and .names file ZIP, and get config json if it exist
        for await (const item of dirHandleAux.values()) {
            try {
                if (item.kind === 'file') {
                    let fileAux = new LocalFile(item)
                    changeTextLoadingMessage(fileAux.name)
                    if (validExtensionImage(getFileExtension(fileAux.name))) { //If is it a Image?
                        let imageFile = await fileAux.getTheFile();
                        let imageObj = await loadImage(imageFile)
                        if (imageObj !== null){
                            imageObj.name = imageFile.name
                            listImagesObjAux.push(imageObj)
                            //https://stuk.github.io/jszip/documentation/api_jszip/file_data.html
                            zipAux.file(imageFile.name, imageFile);
                        }

                    }
                    if ('names' === getFileExtension(fileAux.name)) { //if is a tags file
                        let file = await fileAux.getTheFile();
                        let lines = await fileAux.readLinesFile()
                        listClassesAux = getListClasesFromYoloFormat(lines)
                        let newNamesFile = getNamesFileFromYoloFormat(lines)
                        //console.log('newNamesFile ', newNamesFile)
                        zipAux.file(file.name, newNamesFile);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }

        //Create a coord files with AutomataQA format form yolo '.txt' files 
        let contAux = 0
        if (listClassesAux.length !== 0 && dirHandleAux !== null) {
            for await (const image of listImagesObjAux) {
                contAux += 1
                changeTextLoadingMessage(image.name)
                changeFooterLoadingMessage(`${contAux} de ${listImagesObjAux.length * 2}`)
                try {
                    //Get a res file for each image
                    let yoloFileHandleThisImage = await dirHandleAux.getFileHandle(getFileNameWithoutExtensions(image.name) + formatYoloFile, { create: false })
                    let yoloFileThisImage = new LocalFile(yoloFileHandleThisImage)
                    let contentYolo = await yoloFileThisImage.readFile()
                    if (contentYolo !== '') {
                        //Read lines
                        let linesYolo = await yoloFileThisImage.readLinesFile()
                        listContentYoloFilesAux.push(contentYolo)
                        //Added new file on yolo formats
                        let newContentResFormat = getContentFormatAutomataQAFromYoloLines(listClassesAux, linesYolo, yoloFileThisImage.name, image)
                        listContentResFilesAux.push(newContentResFormat)
                        zipAux.file(image.name + formatResFile, newContentResFormat);
                    }else{
                        console.warn(`El archivo [${getFileNameWithoutExtensions(image.name) + formatYoloFile}] esta vacio...`);
                        SmallNoty('warning', `El archivo [${getFileNameWithoutExtensions(image.name) + formatYoloFile}] esta vacio...`)
                    }
                } catch (error) {
                    console.error(error);
                    console.error(`El archivo [${getFileNameWithoutExtensions(image.name) + formatYoloFile}] no existe o esta vacio...`);
                    SmallNoty('error', `El archivo [${getFileNameWithoutExtensions(image.name) + formatYoloFile}] no existe o esta vacio...`)
                }
            }
        }

        console.log('listImagesObjAux: ', listImagesObjAux)
        console.log('listClassesAux: ', listClassesAux)
        console.log('listContentYoloFilesAux', listContentYoloFilesAux)
        console.log('listContentResFilesAux', listContentResFilesAux)
        console.log('zipAux: ', zipAux)
        setListClasses(listClassesAux)
        setDirHandle(dirHandleAux)
        setListContentResFiles(listContentResFilesAux)
        setListContentYoloFiles(listContentYoloFilesAux)
        setListImages(listImagesObjAux)
        setZip(zipAux)
        Swal.close();
    }

    return (
        <Fragment>
            <div className='container'>
                <div className='row m-2'>
                    <h3 className='text-center'>Convertidor proyectos a formato AutomataQA</h3>
                </div>
                <div className='row m-2'>
                    <p className='text-center'>Carga un proyecto <b>en formato YOLO</b> dando click, verifica la salida y da click en descargar</p>
                </div>
                <div className='row m-2'>
                    <button
                        className="btn btn-primary" type="button"
                        onClick={() => { selectProyectDirectory() }}
                    >
                        Cargar proyecto
                    </button>
                </div>
                <br />
                {/*Info proyecto*/}
                <div className='row m-2'>
                    <ProyectInfo
                        dirHandle={dirHandle}
                        listContentYoloFiles={listContentYoloFiles}
                        listClasses={listClasses}
                        listImages={listImages}
                    />
                </div>
                <br />
                {/*Clases*/}
                <div className='row m-2'>
                    <TableClasses
                        listClasses={listClasses}
                    />
                </div>
                <br />
                {/*Vista previa formatos */}
                <div className='row m-2'>
                    <PreviewFilesView
                        listContentResFiles={listContentResFiles}
                        listContentYoloFiles={listContentYoloFiles}
                    />
                </div>
                <br />
                {/*Botton descargar */}
                <div className='row m-2'>
                    <ButtonDownload
                        dirHandle={dirHandle}
                        zip={zip}
                    />
                </div>
            </div>
            <br />
            <Footer />
        </Fragment>
    )
}

export default AutomataConverter