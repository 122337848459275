import React, {Fragment, useState}from 'react'
import './sidebar.css'
import CardImage from '../CardImage'
import { ReactComponent as ImagesIcon } from '../../../static/icons/images.svg'

export const LeftSideBar = (props) => {
    
    const canvas = props.canvas
    const listImagesFileHandler = props.listImagesFileHandler
    const listTags = props.listTags
    const dirHandle = props.dirHandle
    const triggerUpdateQuantityTags = props.triggerUpdateQuantityTags
    const setTriggerUpdateQuantityTags = props.setTriggerUpdateQuantityTags
    const resFileFormat = props.resFileFormat
    const [checkIndexImage, setCheckIndexImage] = useState(null)
    
    return (
        <Fragment>
            {/*Titulo*/}
            <div className='row align-middle' style={{ height: '6%' }}>
                <h5 className='text-white m-0 pt-2'>
                    <ImagesIcon /> &nbsp; Imagenes
                </h5>
            </div>
            {/*Cards*/}
            <div className='row scroll' style={{ height: '94%' }}>
            {listImagesFileHandler.map((element, index) =>
                <CardImage 
                    key={index.toString()} 
                    imageFileHandler={element} 
                    canvas={canvas} 
                    index={index}
                    checkIndexImage={checkIndexImage}
                    setCheckIndexImage={setCheckIndexImage}
                    dirHandle={dirHandle}
                    listTags={listTags}
                    triggerUpdateQuantityTags={triggerUpdateQuantityTags}
                    setTriggerUpdateQuantityTags={setTriggerUpdateQuantityTags}
                    resFileFormat={resFileFormat}
                />
            )}
            </div>
        </Fragment>
    )
}

export default LeftSideBar