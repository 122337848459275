import React, { useState, useEffect, Fragment } from 'react'

import { roundFloat } from '../../helpers/numbers'

export default function UsersTable(props) {

    
    const listJsonLogs = props.listJsonLogs
    const [dirUsers, setDirUsers_Time] = useState(null)

    useEffect(() => {
        let dirUsers_TimeAux = {}
        if (listJsonLogs !== null){
            listJsonLogs.forEach((log) => {
                if (dirUsers_TimeAux[log.user]) {
                    dirUsers_TimeAux[log.user]["totalTime"] += log.duration
                } else { //Create key using user name & set default values
                    dirUsers_TimeAux[log.user] = {}
                    dirUsers_TimeAux[log.user]["totalTime"] = 0
                    dirUsers_TimeAux[log.user]["added"] = 0
                    dirUsers_TimeAux[log.user]["deleted"] = 0
                    dirUsers_TimeAux[log.user]["changed"] = 0
                    dirUsers_TimeAux[log.user]["totalTime"] += log.duration
                }
                log.descriptions.forEach(description => {
                    try {
                        let acctionType = description.acctionType
                        if (acctionType === 'Added') {
                            dirUsers_TimeAux[log.user]['added'] += 1
                        }
                        if (acctionType === 'Deleted') {
                            dirUsers_TimeAux[log.user]['deleted'] += 1
                        }
                        if (acctionType === 'Changed') {
                            dirUsers_TimeAux[log.user]['changed'] += 1
                        }
                    } catch (error) {
                        console.error(error)
                    }
                });
            })
            console.log(dirUsers_TimeAux)
            setDirUsers_Time(dirUsers_TimeAux)
        }
    }, [listJsonLogs])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
        {
            (dirUsers) &&
            <Fragment>
                <div className="card-header text-center"><h3>Usuarios</h3></div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Usuario</th>
                            <th scope="col">Tiempo total (min)</th>
                            <th scope="col">Etiquetas Agregadas</th>
                            <th scope="col">Etiquetas Eliminadas</th>
                            <th scope="col">Etiquetas Cambiadas</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(dirUsers).map((key, index) =>
                            <tr key={index}>
                                <th scope="row">{key}</th>
                                <td>{roundFloat(dirUsers[key].totalTime / 60, 2)}</td>
                                <td>{dirUsers[key]["added"]}</td>
                                <td>{dirUsers[key]['deleted']}</td>
                                <td>{dirUsers[key]['changed']}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Fragment>
        }
        </Fragment>
    )
}
