import React, {Fragment} from 'react'
import Button from './Button'

//Models
import { LocalFile } from '../../models/LocalFile';

// Icons for the toolbar buttons
import LogoSquare from '../../static/images/logo-square.png'
import { ReactComponent as LockIconSVG } from '../../static/icons/lock.svg'
import { ReactComponent as FilesIconSVG } from '../../static/icons/files.svg'
import { ReactComponent as PencilIconSVG } from '../../static/icons/pencil.svg'
import { ReactComponent as TrashIconSVG } from '../../static/icons/trash.svg'
import { ReactComponent as FolderPlusIconSVG } from '../../static/icons/folder-plus.svg'
import { ReactComponent as AspectRatioIconSVG } from '../../static/icons/aspect-ratio.svg'
import { ReactComponent as UnlockIconSVG } from '../../static/icons/unlock.svg'
import { ReactComponent as CursorIconSVG } from '../../static/icons/cursor.svg'
import { ReactComponent as CloudDownIconSVG } from '../../static/icons/cloud-arrow-down.svg'
//import HandUpIcon from '../static/icons/hand-thumbs-up.svg'
//import HandDownIcon from '../static/icons/hand-thumbs-down.svg'
import { ReactComponent as ChatLeftIconSVG } from '../../static/icons/chat-left-dots.svg'
import { ReactComponent as AddIconSVG } from '../../static/icons/plus-square.svg'
import { ReactComponent as SwitchIconSVG } from '../../static/icons/arrow-repeat.svg'
import { ReactComponent as FolderOpenIconSVG } from '../../static/icons/folder2-open.svg'
import { ReactComponent as ImagesIconSVG } from '../../static/icons/images.svg'
import { ReactComponent as TagsIconSVG } from '../../static/icons/tags.svg'
import { ReactComponent as InfoIconSVG } from '../../static/icons/info-circle.svg'
import { ReactComponent as FileZIPIconSVG } from '../../static/icons/file-earmark-zip.svg'

//Vendors
import Swal from 'sweetalert2'
import { SmallNoty, LoadingMessage, changeTextLoadingMessage, changeFooterLoadingMessage } from '../../helpers/message'
import JSZip from 'jszip'
import { saveAs } from 'file-saver';

//Helpers
import { verifyPermissionHandle, generateImageLog, saveCurrentPercepthorImageResFile, getLocalFiles} from '../../helpers/fileSystemAPI'
import { deleteActiveObject, lock_unlock_ActiveObject, cloneActiveObject, addPercepthorArticle, switchActiveTag, lock_unlock_AllPercepthorArticles } from '../../helpers/fabricJsCustom'
import { URL_USER_MANUAL } from '../../static/links'

function Header(props) {

    const canvas  = props.canvas
    //const listLocalFiles = props.listLocalFiles
    const setlistLocalFiles = props.setlistLocalFiles
    const listTags = props.listTags
    const setlistTags = props.setlistTags
    const listImagesFileHandler = props.listImagesFileHandler
    const setImagesFileHandler = props.setImagesFileHandler
    const dirHandle = props.dirHandle
    const setDirHandle = props.setDirHandle
    const setActiveTag = props.setActiveTag
    const setFileResFormat = props.setFileResFormat
    
    /**
     * Get data directory source and set hooks
     * @return {Void}  void
     */
    async function loadProject(setDirHandle, setlistLocalFiles, setlistTags, setImagesFileHandler, setFileResFormat) {
        try {
            let json_obj = await getLocalFiles()
            if(json_obj !== null){
                clearUIAndHooks()
                console.log(json_obj)
                setDirHandle(json_obj['dirHandle'])
                setlistLocalFiles(json_obj['listLocalFiles'])
                setlistTags(json_obj['listTags'])
                setImagesFileHandler(json_obj['listImagesFileHandler'])
                setFileResFormat(json_obj['fileResFormat'])
            }
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Save files into directory source
     * @return {Void}  void
     */
    async function saveCurrentChangeToLocalFiles() {

        if (canvas.percepthorImage) { //If there is a current PercepthorImage in PercepthorCanvas
            const fullNameCurrentImage = canvas.percepthorImage.name + '.' + canvas.percepthorImage.extension
            await generateImageLog(dirHandle, fullNameCurrentImage, canvas)
            await saveCurrentPercepthorImageResFile(dirHandle, fullNameCurrentImage, canvas)
            Swal.fire({
                icon: 'success',
                title: 'Tu trabajo ha sido guardado',
                showConfirmButton: false,
                timer: 2000
            })
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Upps... algo salio mal',
                text: 'Acepta los permisos de archivos',
                showConfirmButton: false,
                timer: 2000
            })
            console.log('No hay carpeta seleccionada o imagen en el canvas')
        }
    }

    function setCursorModeCanvas(mode) {
        //Set sleccion mode by default
        let cadAux = 'seleccion'
        canvas.hoverCursor = 'default'
        canvas.cursorMode = mode

        if (mode === 'draw') {
            cadAux = 'dibujo'
            canvas.hoverCursor = 'cell'
        } 
        if (mode === 'inspect') {
            cadAux = 'inspeccion'
            canvas.hoverCursor = 'context-menu'
        }
        SmallNoty('success', `Modo [${cadAux}] en el lienzo activado.`)
    }

    /**
     * Remove Tags, PercepthorImages and all PercepthorArticles form canvas
     * @return {Void}  void
     */
    function clearUIAndHooks() {
        setActiveTag(null)
        setDirHandle(null)
        setlistLocalFiles([])
        setlistTags([])
        setImagesFileHandler([])
        setFileResFormat(null)
        if(canvas){
            canvas.discardActiveObject()
            canvas.getObjects().forEach(function (obj) {
                canvas.remove(obj)
            });
            canvas.removePercepthorImage()
            canvas.activeTag = null
            canvas.removePercepthorArticles()
            canvas.requestRenderAll()
        }
    }
    
    /**
     * Generate a zip whith res files and logs json of current proyect, 
     * @param {FileSystemHandler} dirHandle
     * @param {Array<FileSystemHandler>} listImagesFileHandler, list of each image's file handler  of project
     * @return {Void}  void
     */
    async function exportResultsFilesAndLogsJsonAsZIP(dirHandle, listImagesFileHandler) {
        if (dirHandle){
            let zipAux = new JSZip();
            let res = await verifyPermissionHandle(dirHandle, true)
            if(res){
                let loadingMessage = LoadingMessage(
                    'Por favor espere!',
                    `Extrayendo archivos '.res' y 'log.json" del proyecto`,
                    '',
                )
                loadingMessage.fire()
                
                //Get each res file and jsonlog using each ImageFileHandler
                let contAux = 0
                let totalResAndLogsForImages = listImagesFileHandler.length * 2
                for await (const fileHandle of listImagesFileHandler) {
                    const formatResFile = '.res'
                    const formatLogJSONFile = '.log.json'
                    try {
                        let resFileHandleThisImage = await dirHandle.getFileHandle(fileHandle.name + formatResFile, { create: false })
                        if (resFileHandleThisImage){
                            contAux += 1
                            changeTextLoadingMessage(resFileHandleThisImage.name)
                            changeFooterLoadingMessage(`${contAux} de ${totalResAndLogsForImages}`)
                            let fileHandle = new LocalFile(resFileHandleThisImage)
                            let resFile = await fileHandle.getTheFile();
                            zipAux.file(resFile.name, resFile);
                        }
                        let jsonLogFileHandleThisImage = await dirHandle.getFileHandle( fileHandle.name + formatLogJSONFile, { create: false })
                        if (jsonLogFileHandleThisImage) {
                            contAux += 1
                            changeTextLoadingMessage(jsonLogFileHandleThisImage.name)
                            changeFooterLoadingMessage(`${contAux} de ${totalResAndLogsForImages}`)
                            let fileHandle = new LocalFile(jsonLogFileHandleThisImage)
                            let logJsonFile = await fileHandle.getTheFile();
                            zipAux.file(logJsonFile.name, logJsonFile);
                        }
                       
                    } catch (error) {
                        console.error(error);
                    }
                }
                Swal.close();//close all swal 
                
                //Fire download ZIP with data
                zipAux.generateAsync({ type: "blob" })
                    .then(function (blob) {
                        let dateString = new Date().toDateString()
                        saveAs(blob, `${dirHandle.name}_ResAndLog_${dateString}.zip`);
                    });
            }
            
        }else{
            SmallNoty('error', `No hay un proyecto cargado...`)
        }
    }

    
    return (
        <Fragment>
            <div className="col-sm-1 col-md-1 col-lg-1 text-center">
                <a href="http://percepthor.com/" target="_blank" rel="noreferrer" >
                    <img className="img-fluid" src={LogoSquare} alt="logo" width="80%" />
                </a>
            </div>

            <div className="col-sm-2 col-md-2 col-lg-2 text-center text-light">
                <h3 className='m-0'>Autómata <sup>QA</sup></h3>
                <p className='m-0'>
                    {dirHandle?.name && <small><FolderOpenIconSVG /> {dirHandle?.name}</small>}&nbsp;
                    {listTags?.length !== 0 && <small className="text-white"> <TagsIconSVG />&nbsp;{listTags?.length}</small>} &nbsp;
                    {listImagesFileHandler?.length !== 0 && <small className="text-white"> <ImagesIconSVG />&nbsp;{listImagesFileHandler?.length}</small>}
                </p>
            </div>

            <div className="col-sm-3 col-md-3 col-lg-3 text-center">
                <div className="row">
                    <h5 className="text-light m-0" onClick={
                        () => {
                            console.log(canvas)
                            SmallNoty('info', `Mensaje prueba :)`)
                        }
                    }>Lienzo</h5>
                </div>
                <div className="row justify-content-center">
                    <Button functionToExcecute={() => setCursorModeCanvas('select')} tooltip='Modo seleccion' >
                        <CursorIconSVG/>
                    </Button>
                    <Button functionToExcecute={() => setCursorModeCanvas('draw')} tooltip='Modo dibujar' >
                        <PencilIconSVG/>
                    </Button>
                    <Button functionToExcecute={() => setCursorModeCanvas('inspect')} tooltip='Modo inspeccionar' >
                        <ChatLeftIconSVG/>
                    </Button>
                    <Button functionToExcecute={() => canvas.setFitZoom()} tooltip='Ajustar imagen al lienzo' >
                        <AspectRatioIconSVG/>
                    </Button>
                </div>
            </div>

            <div className="col-sm-3 col-md-3 col-lg-3 text-center">
                <div className="row">
                    <h5 className="text-light m-0">Artículos</h5>
                </div>
                <div className="row justify-content-center">
                    <Button functionToExcecute={() => addPercepthorArticle(canvas, canvas?.activeTag)} tooltip='Agregar'>
                        <AddIconSVG/>
                    </Button>
                    <Button functionToExcecute={() => switchActiveTag(canvas, canvas?.activeTag)} tooltip='Cambiar' >
                        <SwitchIconSVG/>                        
                    </Button>
                    <Button functionToExcecute={() => cloneActiveObject(canvas)} tooltip='Clonar' >
                        <FilesIconSVG/>                    
                    </Button>
                    <Button 
                        functionToExcecute={() => lock_unlock_ActiveObject(canvas, true)} 
                        functionToExcecuteRightClick={() => lock_unlock_AllPercepthorArticles(canvas, true)}
                        tooltip='Bloquear' >
                        <LockIconSVG/>                    
                    </Button>
                    <Button 
                        functionToExcecute={() => lock_unlock_ActiveObject(canvas, false)} 
                        functionToExcecuteRightClick={() => lock_unlock_AllPercepthorArticles(canvas, false)}
                        tooltip='Desbloquear' >
                        <UnlockIconSVG/>                    
                    </Button>
                    <Button functionToExcecute={() => deleteActiveObject(canvas)} tooltip='Eliminar' >
                        <TrashIconSVG/>                    
                    </Button>
                    {/*<Button icon={HandUpIcon} functionToExcecute={() => calPercepthorArticle(canvas, true)} tooltip='Correcto' />
                    <Button icon={HandDownIcon} functionToExcecute={() => calPercepthorArticle(canvas, false)} tooltip='Incorrecto' />*/}
                </div>
            </div>

            <div className="col-sm-3 col-md-3 col-lg-3 text-center ">
                <div className="row">
                    <h5 className="text-light m-0">Archivos</h5>
                </div>
                <div className="row justify-content-center">
                    <Button functionToExcecute={() => loadProject(setDirHandle, setlistLocalFiles, setlistTags, setImagesFileHandler, setFileResFormat)} tooltip='Cargar Proyecto' >
                        <FolderPlusIconSVG/>
                    </Button>
                    <Button functionToExcecute={() => saveCurrentChangeToLocalFiles()} tooltip='Guardar Cambios' >
                        <CloudDownIconSVG/>
                    </Button>
                    <Button functionToExcecute={() => exportResultsFilesAndLogsJsonAsZIP(dirHandle, listImagesFileHandler)} tooltip={`Extraer archivos '.res' y 'json.log' del proyecto`} >
                        <FileZIPIconSVG />
                    </Button>
                    <Button functionToExcecute={() => { window.open(URL_USER_MANUAL) }} tooltip='Manual/Ayuda' >
                        <InfoIconSVG />
                    </Button>
                </div>
            </div>
        </Fragment>
    )
}

export default Header
