const red = [255, 20, 20];
const yellow = [255, 233, 20];
const green = [20, 255, 20];
const lightBlue = [20, 182, 255];
const purple = [163, 20, 255];
const orange = [255, 148, 20];
const lime = [125, 255, 20];
const aqua = [20, 255, 224];
const blue = [20, 82, 255];
const pink = [251, 20, 255];

/**
 * Class to create a Singleton RGBColor
 */
export class RGBColor{

    count = 0;

    constructor(){
        //Singleton
        if(typeof RGBColor.instance === 'object'){
            return RGBColor.instance
        }else{
            RGBColor.instance = this
            return this
        }
    }

    /**
     * Get some color on RGB format
     * @return {Array<Number>}  Array format [R,G,B]
     */
    getColor(){
        this.count += 1
        switch (this.count) {
            case 1:
                return red;
            case 2:
                return yellow;
            case 3:
                return green;
            case 4:
                return lightBlue;
            case 5:
                return purple;
            case 6:
                return orange;
            case 7:
                return lime;
            case 8:
                return aqua;
            case 9:
                return blue;
            case 10:
                this.count = 0
                return pink;
            default:
                return red;
        }
    }

}
